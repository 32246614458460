import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Util} from '../common/Util';

interface HomeNews {
  id: String;
  title: String;
  url: String;
  news_image: String;
  posted_date: String;
  desc:String;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  worldData:HomeNews[] = [];
  indiaNews:HomeNews[] = [];
  techNews:HomeNews[] = [];
  politicsNews:HomeNews[] = [];
  sportsNews:HomeNews[] = [];
  private _jsonWorldURL = '../assets/app-assets/data/world-news.json';
  private _jsonIndiaURL = '../assets/app-assets/data/india-top-news.json';
  private _jsonTechURL = '../assets/app-assets/data/tech-news.json';
  private _jsonPoliticsURL = '../assets/app-assets/data/business-news.json';
  private _jsonSportsURL = '../assets/app-assets/data/sports-news.json';

  constructor(private http: HttpClient, public util:Util) {
  }

  ngOnInit(): void {
    this.loadWorlData().subscribe(data => this.worldData = data, error => console.log(error));
    this.loadIndiaData().subscribe(data => this.indiaNews = data, error => console.log(error));
    this.loadTechNews().subscribe(data => this.techNews = data, error => console.log(error));
    this.loadPoliticsNews().subscribe(data => this.politicsNews = data, error => console.log(error));
    this.loadSportsNews().subscribe(data => this.sportsNews = data, error => console.log(error));
  }

  private loadWorlData(): Observable<any>{
    return this.http.get(this._jsonWorldURL)
  }

  private loadIndiaData(): Observable<any>{
    return this.http.get(this._jsonIndiaURL)
  }
  private loadTechNews(): Observable<any>{
    return this.http.get(this._jsonTechURL)
  }
  private loadPoliticsNews(): Observable<any>{
    return this.http.get(this._jsonPoliticsURL)
  }
  private loadSportsNews(): Observable<any>{
    return this.http.get(this._jsonSportsURL)
  }

}
