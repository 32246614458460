import {Injectable, Injector} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {CookieService} from 'ngx-cookie-service';
import {NotificationService} from '../notification.service';


@Injectable()
export class Interceptor implements HttpInterceptor {

  cookieValue: any;
  contentType : any ='';

  constructor(private cookieService: CookieService,
              private notifyService: NotificationService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    console.log(request)

    if(request.headers.has("Content-Type"))
      this.contentType  = request.headers.get("Content-Type");
    else
      this.contentType  = 'application/json';

    this.cookieValue = this.cookieService.get('access_token');
    if (request.body instanceof FormData) {
      var AuthRequest = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + this.cookieValue,
        }
      });
    }
    else if (!this.cookieValue) {
      var AuthRequest = request.clone({
        setHeaders: {
          'Content-Type': this.contentType
        }
      });
    }
      else {
      var AuthRequest = request.clone({
        setHeaders: {
          'Content-Type': this.contentType,
          Authorization: 'Bearer ' + this.cookieValue,
        }
      });
    }

    return next.handle(AuthRequest).pipe(
      catchError((error: HttpErrorResponse) => {
            if(error.error.statusCode==""){
              if (error.error.message) {
                this.notifyService.showError(error.error.message,"Error");
              }

              else if(error.error) {
                this.notifyService.showError(error.error, 'Error');
              }
              else {
                this.notifyService.showError(error, 'Error');
              }
            }
            if(error.status==403) {
              this.notifyService.showError("You are not authorised to use this service", 'Not Authorised');
            }
            return throwError(error);
      }));
  }
}
