import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import {Util} from '../common/Util';
import {PrintMedia} from './print-media';
import { saveAs } from 'file-saver';

import {TextNewsComponent} from './text-news/text-news.component';
import {ActivatedRoute} from '@angular/router';
import {NotificationService} from '../notification.service';
import {SearchPrintmediaComponent} from './search-printmedia/search-printmedia.component';
import {PrintmediaSearch} from './printmedia-search';



@Component({
  selector: 'app-social-media',
  templateUrl: './print-media.component.html',
  styleUrls: ['./print-media.component.scss']
})
export class PrintMediaComponent implements OnInit {

  current_archive:any = 'CURR';
  todaysPrintMediaNewsList: PrintMedia[]=[];
  searchVal: string;
  searchVal2: string='';
  page:number = 1;
  sortDirection: string = 'imageDate';
  columnName: string = 'imageDate';
  searchClient: string = '';
  isLoading:boolean = false;
  selectAll:boolean = false;
  fromdate:string = this.util.getYesterdayDate();
  todate:string = this.util.getYesterdayDate();
  iscurrent:boolean = true;
  searchData:PrintmediaSearch={
    publication : {
      publication : '',
      publicationId:''
    },
    client:{
      clientId:'',
      clientName:''
    },
    edition:{
      cityId:'',
      cityName:''
    },
    fromDate:this.util.getYesterdayDate(),
    toDate:this.util.getYesterdayDate(),
    headline:''
  };

  selectedFeedids:string = ''
  appData:any;
  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;
  changeText:boolean = false;
  constructor(
              private matDialog: MatDialog,
              private allService: AllService,
              private notifyService: NotificationService,
              private cookieService: CookieService,
              public util:Util,

              private _Activatedroute:ActivatedRoute
  ) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
    this.searchVal2 = 'L24H';
  }


  sort(column:string) {

    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.columnName = column;
    this.getTodaysPrintMediaNews();
  }

  ngOnInit(): void {

    this._Activatedroute.paramMap.subscribe(params=>{
      this.iscurrent =   params.get("type")?.toUpperCase()=="CURR" ? true : false;
      this.searchVal2 = '';
      this.searchVal = '';
      this.getTodaysPrintMediaNews();
    })
  }
  getTodaysPrintMediaNews(): void {



    this.todaysPrintMediaNewsList = [];

    if(!this.iscurrent){

      if(this.fromdate==""){
        this.notifyService.showError("Please select valid from & to dates","Archive Print Media")
        return;
      }
      this.getArchiveData(this.searchData);
    }
    else{
      this.isLoading=true;
      this.allService.getTodaysPrintMediaNewsFeeds(this.searchVal,this.columnName,this.sortDirection).subscribe((data: any) => {
        console.log(data);
        this.todaysPrintMediaNewsList = data;
        this.isLoading=false;
      })
    }
  }


  downloadZip(){
    if(this.getSelectedFeedIds()){
      this.isLoading = true;
      this.allService.downloadBundledZipFile(this.selectedFeedids,this.searchClient,this.iscurrent).subscribe((blob: any) => {
        saveAs(blob, "Merged.zip")
        this.isLoading=false;
        this.selectedFeedids = '';
      });
    }else{
      this.notifyService.showError("Please select any News to download zip file","Download Zip");
    }

  }

  getMerged(){

    if(this.getSelectedFeedIds()) {
      this.isLoading = true;
      this.allService.mergeTodaysPrintMediaNewsFeeds(this.selectedFeedids, this.searchClient, this.iscurrent).subscribe((blob: any) => {
        saveAs(blob, "YourDownload-" + new Date().getTime())
        this.isLoading = false;
        this.selectedFeedids = '';
      });
    }else{
      this.notifyService.showError("Please select any News to download file","Merge News");
    }

  }

  openDialog(data:any){


    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data
    this.matDialog.open(TextNewsComponent, dialogConfig);
  }

  openSearchDialog(data:any){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data
    const ref = this.matDialog.open(SearchPrintmediaComponent, {
      height: '400px',
      width: '1200px',
      data:{isCurrent:this.iscurrent}
    });
    const sub = ref.componentInstance.onAdd.subscribe((data) => {
      if(this.iscurrent) {
        this.allService.getTodaysPrintMediaNewsFeedsAdvanced(data.publication.publication, data.edition.cityName, data.client.clientName, data.headline, this.columnName, this.sortDirection).subscribe((data: any) => {
          console.log(data);
          this.todaysPrintMediaNewsList = data;
          this.isLoading = false;
        })
      }else{
        this.getArchiveData(data);
      }

    });
  }


  getSelectedFeedIds(){
    this.todaysPrintMediaNewsList.forEach(item=>{
      if(item.is_selected)
        this.selectedFeedids+=item.printMediaId + ","
    })

    if(this.selectedFeedids.length > 0){
      this.selectedFeedids.substring(0,this.selectedFeedids.length - 1)
      return true;
    }else
      return false;
  }

  getArchiveData(data:any){
    this.searchData = data;
    let fromdt = this.searchData.fromDate==""? this.util.getYesterdayDate() : this.searchData.fromDate;
    let todt = this.searchData.toDate==""? this.util.getYesterdayDate() : this.searchData.toDate;

    this.allService.getArchivePrintMediaNewsFeedsAdvanced(fromdt,todt,this.searchData.publication.publication, this.searchData.edition.cityName, this.searchData.client.clientName, '', this.columnName, this.sortDirection,this.page).subscribe((data: any) => {
      this.todaysPrintMediaNewsList = data.data;
      this.totalCount = data.totalRecordCount;
      this.isLoading=false;
    });
  }

  clearSearch(){
      this.searchData = {
        publication : {
          publication : '',
          publicationId:''
        },
        client:{
          clientId:'',
          clientName:''
        },
        edition:{
          cityId:'',
          cityName:''
        },
        fromDate:this.util.getYesterdayDate(),
        toDate:this.util.getYesterdayDate(),
        headline:''
      };
  }

}
