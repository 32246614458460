import {Component, OnInit} from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  Validators
} from '@angular/forms';
import {User} from './user';
import {AllService} from '../../all.service';
import {CookieService} from 'ngx-cookie-service';
import {ManageuserService} from '../../services/manageuser.service';
import {NotificationService} from '../../notification.service';
import {UsernameValidator} from '../../validators/username.validator';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-social-media',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css'],

})
export class UserComponent implements OnInit {

  userList:User[]=[];
  data: any;
  clientValue: any;
  page:number = 1;
  sortDirection: string = 'asc';
  columnName: string = 'userName';
  p: number = 1;
  collection: any;
  totalCount:number = 1;
  client = 'clientName';
  formData:User = {
    firstName: '',
    lastName: '',
    userName:'',
    userType:'',
    password:'',
    userRoles:[],
    clients:''
  };

  UserForm: FormGroup;
  selectedCity: any;

  constructor(private allService: AllService,
              private userservice: ManageuserService,
              private fb: FormBuilder,
              private usernameValidator: UsernameValidator,
              private cookieService: CookieService,
              private notifyService: NotificationService,) {
    this.UserForm = this.fb.group({
      username: ['',[Validators.required,Validators.minLength(5),Validators.maxLength(30)],UsernameValidator.createValidator(this.userservice)],
      password:['',[Validators.required,Validators.maxLength(30),Validators.minLength(10)]],
      firstname:['',Validators.required],
      lastname:['',Validators.required],
      usertype:['',Validators.required],
      userrole:[],
      clientObject: ['',Validators.required]
    })
  }

    ngOnInit(): void {
      this.page = 1;
      this.getUsersList();
    }

    get username() {
      return this.UserForm.get('username');
    }

    get password(){
      return this.UserForm.get('password')
    }

    saveUser(){

      if(this.UserForm.valid){
        this.formData.userName = this.UserForm.get("username")?.value;
        this.formData.firstName = this.UserForm.get("firstname")?.value;
        this.formData.lastName = this.UserForm.get("lastname")?.value;
        this.formData.userType = this.UserForm.get("usertype")?.value;
        this.formData.userRoles = this.UserForm.get("userrole")?.value;
        this.formData.password = this.UserForm.get("password")?.value;
        this.formData.clients = this.UserForm.get("clientObject")?.value.clientId;

        this.userservice.addUser(this.formData).subscribe((data:any)=>{
          this.notifyService.showSuccess(data.message,"Manage User")
          this.getUsersList();
          this.UserForm.reset();
        },(error:any) => this.notifyService.showError(error.message,"Manage User"));
      }else{
        this.notifyService.showError("All required fields are not filled.","Manage User")
      }

    }
    selectEvent(item: any) {
      this.clientValue = item.clientId;
    }

    onChangeSearch(val: string) {
      this.allService.getClients(val).subscribe((data: any) => {
        this.data = data;
      });
    }

    getUsersList(): void{
      this.userList = [];
      console.log(this.page)
      this.userservice.getAllUsers(this.columnName,this.sortDirection,this.page).subscribe((data:any)=>{
        console.log(data)
        this.userList = data.data;
        this.totalCount = data.totalRecordCount;
      })
    }

    handleUserDelete(userid:any){
      Swal.fire({
        title: 'Are you sure want to remove user?',
        text: 'You will not be able to recover the user!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result)=>{
          if(result.value){
            this.userservice.deleteUser(userid).subscribe((data:any)=>{
              this.getUsersList();
              Swal.fire(
                'Deleted!',
                'User deleted!!',
                'success'
              )
            })
          }
      })
    }

}
