import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {AllService} from '../all.service';
import {DialogBodyOnlineComponent} from './dialog-body-online/dialog-body-online.component';
import {NewsDescComponent} from './news-desc/news-desc.component';
import {ToastrService} from "ngx-toastr";
import {CookieService} from "ngx-cookie-service";
import {Util} from '../common/Util';

@Component({
  selector: 'app-online-media',
  templateUrl: './online-media.component.html',
  styleUrls: ['./online-media.component.scss']
})
export class OnlineMediaComponent implements OnInit {

  todayOnlineMediaList: any;
  searchVal: string;
  page:number = 1;

  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;

  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              public util:Util) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
  }


  ngOnInit(): void {
    this.getTodaySocialMediaData();
  }

  getTodaySocialMediaData(): void {
    this.allService.getOnlineMediaData(this.searchVal, this.page).subscribe((data: any) => {
      this.todayOnlineMediaList = data;
      this.totalCount = this.todayOnlineMediaList.pages * 10;
      console.log(data);

    });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(DialogBodyOnlineComponent, dialogConfig);
  }

  openDescription(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(NewsDescComponent, dialogConfig);
  }

  deleteOnlineMediaEntry(data: any) {
    this.allService.deleteOnlineMediaData(data.id).subscribe((data: any) => {
      console.log(data);
      this.toastr.success("Entry Deleted..!!", "Success")
    });
  }

}
