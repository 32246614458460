import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AllService} from '../../all.service';
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-dialog-body-online',
  templateUrl: './dialog-body-online.component.html',
  styleUrls: ['./dialog-body-online.component.scss']
})
export class DialogBodyOnlineComponent implements OnInit {

  abc: any;

  updateData: any;
  updateData1: any;
  keyword = 'clientName';
  keywordCategory = 'categoryName';


  clientValue: any;
  clientId: any;
  categoryValue: any;
  categoryId: any;
  data: any;
  dataCategory: any;


  constructor(public dialogRef: MatDialogRef<DialogBodyOnlineComponent>,
              @Inject(MAT_DIALOG_DATA) public data1: any,
              private allService: AllService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.updateData = this.data1;
    console.log(this.updateData);
  }

  closeUpdate() {
    this.dialogRef.close();
    window.location.reload()
  }

  close() {
    this.dialogRef.close();
    window.location.reload()
  }

  selectEvent(item: any) {
    // do something with selected item
    this.clientId = item.clientId;
    this.clientValue = item.clientName;
  }

  selectCategoryEvent(item: any) {
    // do something with selected item
    this.categoryId = item.categoryId;
    this.categoryValue = item.categoryName;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.allService.getClients(val).subscribe((data: any) => {
      this.data = data;
      console.log(data);
    });
  }

  onClientChangeSearch(val: string) {
    this.categoryValue = val;

    this.allService.getCategory(val, this.clientId).subscribe((data: any) => {
      this.dataCategory = data;
      console.log(data);
    });
  }

  addItem() {

    let copyObj = JSON.parse(JSON.stringify(this.updateData))
    copyObj.newsClientCategories.push({
      onlineNews: this.updateData.id,
      clientId: this.clientId,
      categoryId: this.categoryId
    })

    this.allService.updateOnlineMedia(copyObj).subscribe((data: any) => {

      console.log(data);

      this.updateData.newsClientCategories.push({
        clientId: this.clientId,
        clientName: this.clientValue,
        categoryId: this.categoryId,
        categoryName: this.categoryValue
      })
    })
  }

  deleteItem(data:any, index: number) {

    let copyObj = JSON.parse(JSON.stringify(this.updateData))
    copyObj.newsClientCategories.splice(index, 1);

    if(data.id==undefined)
      this.updateData.newsClientCategories.splice(index, 1);
    else{
        this.allService.deleteOnlineMediaMapping(data.id).subscribe((data: any) => {
          this.toastr.success("Mapping Deleted..!!", "Success")
          this.updateData.newsClientCategories.splice(index, 1);
        },error => {
        });
      }
    }
}
