import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {AllService} from '../all.service';
import {DialogBodyOnlineArchieveComponent} from './dialog-body-online-archieve/dialog-body-online-archieve.component';
import {NewsDescArchieveComponent} from './news-desc-archieve/news-desc-archieve.component';
import {ToastrService} from "ngx-toastr";
import {CookieService} from "ngx-cookie-service";

@Component({
  selector: 'app-online-media-archieve',
  templateUrl: './online-media-archieve.component.html',
  styleUrls: ['./online-media-archieve.component.scss']
})
export class OnlineMediaArchieveComponent implements OnInit {

  todayOnlineMediaList: any;
  searchVal: string;
  page:number = 1;

  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;

  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
  }


  ngOnInit(): void {
    this.getTodaySocialMediaData();
  }

  getTodaySocialMediaData(): void {

    /*this.todayOnlineMediaList = {

      "tweets": [

        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "media could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        },
        {

          "id": "605807724e84d19bccf60eec",

          "tweet": "mwdfedia could not be played.ReloadReload",

          "likesCount": null,

          "commentCount": null,

          "tweetedBy": "",

          "noOfReplies": "",

          "reTweetsCount": "",

          "tweetedOn": null,

          "clientMetaData": [

            {

              "category": "Direct",

              "client": "KIR100",

              "opinionMaker": "Ram Lal"

            }

          ]

        }

      ],

      "pages": 13,

      "pageNo": 2

    }*/

    // this.collection = this.todayOnlineMediaList.tweets;
    // this.totalCount = this.todayOnlineMediaList.tweets.length * this.todayOnlineMediaList.pages;

    this.allService.getOnlineMediaData(this.searchVal, this.page).subscribe((data: any) => {
      this.todayOnlineMediaList = data;
      this.totalCount = this.todayOnlineMediaList.pages * 10;
      console.log(data);

    });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(DialogBodyOnlineArchieveComponent, dialogConfig);
  }

  openDescription(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(NewsDescArchieveComponent, dialogConfig);
  }

  deleteOnlineMediaEntry(data: any) {
    this.allService.deleteOnlineMediaData(data.id).subscribe((data: any) => {
      this.toastr.success("Entry Deleted..!!", "Success")
    });
  }

}
