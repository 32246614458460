import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import {FacebookDialogBodyComponent} from './dialog-body/facebook-dialog-body.component';
import {Util} from '../common/Util';
import {Facebook} from './facebook';

@Component({
  selector: 'app-social-media',
  templateUrl: './facebook-media.component.html',
  styleUrls: ['./facebook-media.component.scss']
})
export class FacebookMediaComponent implements OnInit {

  todaySocialMediaList: Facebook[]=[];
  clientList = [
    {clientid:"",clientname:"--All--",selected:true},
    {clientid:"KIR0001195",clientname:"CBDT",selected:false},
    {clientid:"KIR0001204",clientname:"RAILWAYS",selected:false},
    {clientid:"KIR0001205",clientname:"EDUCATION",selected:false}
  ]
  searchVal: string = '';
  searchVal2: string='';
  page:number = 1;
  sortDirection: string = 'desc';
  columnName: string = 'posted_date';
  searchClient: string = '';
  selectedFeedids:string = ''
  selectAll:boolean = false;
  isLoading:boolean = false;

  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;

  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              public util:Util) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
  }


  ngOnInit(): void {
    this.getTodaySocialMediaData();
    this.getClientAttachedToAdmin();
  }
  getClientAttachedToAdmin():any{
    if(this.userType=="ADMIN"){
      return this.clientList;
    }
  }
  sort(column:string) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.columnName = column;
    this.getTodaySocialMediaData();
  }
  getTodaySocialMediaData(): void {
      this.isLoading=true;
      this.allService.getFacebookMediaData(this.searchVal,this.searchVal2,this.page,this.columnName,this.sortDirection,this.searchClient).subscribe((data: any) => {
      this.todaySocialMediaList = data.feeds;
      console.log(this.todaySocialMediaList);
        this.isLoading=false;
      this.totalCount = data.pages * 10;
    });
  }

  downloadFacebookReport(){

  }

  deleteSocialMediaEntries(){
    this.todaySocialMediaList.forEach(item=>{
      if(item.is_selected)
        this.selectedFeedids+=item.id + ","
    })
    const data = {
      id: this.selectedFeedids.substring(0, this.selectedFeedids.length - 1)
    }
    if(this.selectedFeedids==''){
      alert('Please select atleast 1 row.')
      return false;
    }
    this.deleteSocialMediaEntry(data);
    return ;
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(FacebookDialogBodyComponent, dialogConfig);
  }
  selectAllCheckboxes(){

    this.todaySocialMediaList.forEach(item=>{
      item.is_selected = !this.selectAll
    })
  }
  deleteSocialMediaEntry(data: any) {

    this.allService.deleteFacebookMediaData(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
      );

  }

}
