import { Component, OnInit } from '@angular/core';
import {RouterModule, Router} from '@angular/router';
import jwt_decode from 'jwt-decode';

import {AuthService} from '../auth.service';
import {CookieService} from 'ngx-cookie-service';
import {FormGroup, FormBuilder, FormControl, Validators} from '@angular/forms';
import {NotificationService} from '../notification.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;

  constructor(private router: Router,
              private fb: FormBuilder,
              private authService: AuthService,
              private notifyService: NotificationService,
              private cookieService: CookieService,
              private formBuilder: FormBuilder
  ) {
    this.cookieService.delete('access_token');
    this.loginForm = this.formBuilder.group({
      email: new FormControl('', Validators.required),
      password: new FormControl('', Validators.required)
    });
  }

  ngOnInit(): void {}

  funLogin() {

    const val = this.loginForm.value;

    this.cookieService.delete('access_token');
    this.cookieService.delete('name');

    if (val.email && val.password) {
      let loginData = {
        userId: val.email,
        password: val.password
      }

      this.authService.login(loginData)
        .subscribe(
          (data: any) => {
            this.cookieService.set('access_token', data.jwtToken);
            this.cookieService.set('name', data.username);

            if(data.usertype == "ADMIN") {
              this.cookieService.set('userType', "ADMIN");
              this.cookieService.set('roles', data.roles);
            }
            else {
              this.cookieService.set('userType', "CLIENT");
              this.cookieService.set('roles', data.roles);
            }

            this.router.navigate(['dashboard'])
              .then(() => {
                window.location.reload();
              });
          },(msg)=> {
            this.notifyService.showError(msg.error.message,"BIUS Login");
          }
        );
    }
  }
}
