<div>
  <h1>Text News</h1>
</div>
<form>
  <div class="modal-body">
    <div class="form-group position-relative has-icon-left">
    <span>{{textnews}}</span>
    </div>

  </div>

</form>

