import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {AllService} from '../../all.service';
import {ToastrService} from 'ngx-toastr';
import {AppAlert} from '../../common/AppAlert';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Keyword} from './keyword';
import {MetaInfo} from './meta-info';
import {SocialmediaSourcePipe} from '../../common/socialmedia-source.pipe';
import {CookieService} from 'ngx-cookie-service';

@Component({
  selector: 'app-social-media',
  templateUrl: './manage-keyword.component.html',
  styleUrls: ['./manage-keyword.component.css']
})
export class ManageKeywordComponent implements OnInit {


  data: any;
  metdataList : Keyword[] = [];
  page: number = 1;
  keywordClient = 'clientName';
  clientValue: any;
  categoryValue: any;
  dataCategory: any;
  keywordCategory = 'categoryName';
  totalCount : number = 0;
  userType: any;
  selectedKeywords:string = ''
  selectAll:boolean = false;
  metaInfo = {
    clientCategory:'',
    opinionMaker:'',
    clientId:'',
    status:1,
    twitterHandle:'',
  };

  formData:Keyword = {
    id:'',
    clientMetas:[this.metaInfo],
    keywords:[],
    source:'',
    orderOfSearch:5,
    is_selected:false,
    language: 'en',
    keyword:''
  };
  FormDemo: FormGroup;

  constructor(private allService: AllService,
              private fb: FormBuilder,
              private cookieService: CookieService,
              private toastr: ToastrService) {
    this.FormDemo = this.fb.group({
      clientObject: ['',Validators.required],
      categoryObject:['',Validators.required],
      requiredFor:['TW',Validators.required],
      keywords:['',Validators.required],
      twitterhandle :[''],
      opinionMaker:[''],
      language:['en'],
      searchOrder:[5]
    });

    this.userType = this.cookieService.get('userType');
  }
  ngOnInit(): void {


  }

  getKeywordsList(){

    this.allService.getKeywordsDetails(this.clientValue,this.page).subscribe((data:any)=>{
      console.log(data)
      this.metdataList = data.records;
      this.totalCount = data.totalRecordCount;
    })
  }


  selectEvent(item: any) {
    this.clientValue = item.clientId;
    this.getKeywordsList();
  }
  onChangeSearch(val: string) {
    this.allService.getClients(val).subscribe((data: any) => {
      this.data = data;
    });


  }
  selectCategoryEvent(item: any) {
    this.categoryValue = item.categoryName;
  }
  onClientChangeSearch(val: string) {
    this.categoryValue = val;
    this.allService.getCategory(val, this.clientValue).subscribe((data: any) => {
      this.dataCategory = data;
    });
  }
  addKeyword(){
    if(this.FormDemo.valid){

      this.formData.keywords = this.FormDemo.get("keywords")?.value.split(",");
      this.formData.clientMetas[0].clientId = this.FormDemo.get("clientObject")?.value.clientId;
      this.formData.clientMetas[0].clientCategory = this.FormDemo.get("categoryObject")?.value.categoryName;
      this.formData.clientMetas[0].status = 1;
      this.formData.clientMetas[0].opinionMaker = this.FormDemo.get("opinionMaker")?.value;
      this.formData.clientMetas[0].twitterHandle = this.FormDemo.get("twitterhandle")?.value;
      this.formData.source = this.FormDemo.get("requiredFor")?.value;
      this.formData.orderOfSearch = this.FormDemo.get("searchOrder")?.value;
      this.formData.language = this.FormDemo.get("language")?.value;

      if(this.formData.source=='TW')
         this.formData.language = 'en'

      if(this.formData.source=='GO')
        this.formData.clientMetas[0].twitterHandle = ''

      this.allService.addKeyword(this.formData).subscribe((data: any) => {
        this.toastr.success(data.message, "Success")
        this.FormDemo.get("keywords")?.setValue('')
        this.FormDemo.get("clientObject")?.setValue('')
        this.FormDemo.get("categoryObject")?.setValue('')
        this.FormDemo.get("requiredFor")?.setValue('')
        this.getKeywordsList();
      },err=>{
        this.toastr.error(err.error.message, "Adding Keyword")
      });
    }
  }

  deleteSelectedKeywords(){
    this.metdataList.forEach(item=>{
      if(item.is_selected)
        this.selectedKeywords+=item.id + ","
    })
    const data = {
      id: this.selectedKeywords.substring(0, this.selectedKeywords.length - 1)
    }
    if(this.selectedKeywords==''){
      this.toastr.error('Please select atleast 1 row.','Delete keyword')
      return false;
    }
    this.deleteKeyword(data);
    return ;
  }

  selectAllCheckboxes(){

    this.metdataList.forEach(item=>{
      item.is_selected = !this.selectAll
    })
  }

  deleteKeyword(data:any){
    console.log(data)
    this.allService.deleteKeyword(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.selectedKeywords = '';
        this.getKeywordsList();
        } ,
      (err)=>console.log('In Error')
    );

  }
}
