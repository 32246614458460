<div>
  <h1>Map Client & Category</h1>
</div>
<form>
  <div class="modal-body">
    <label>Client: </label>
    <div class="form-group position-relative has-icon-left">

      <ng-autocomplete
        [data]="data"
        [searchKeyword]="keyword"
        (selected)='selectEvent($event)'
        (inputChanged)='onChangeSearch($event)'
        [itemTemplate]="itemTemplate"
        [notFoundTemplate]="notFoundTemplate"
        placeholder="Client">
      </ng-autocomplete>

      <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.clientName"></a>
      </ng-template>

      <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>

    </div>

    <label>Category: </label>
    <div class="form-group position-relative has-icon-left">
      <ng-autocomplete
        [data]="dataCategory"
        [searchKeyword]="keywordCategory"
        (selected)='selectCategoryEvent($event)'
        (inputChanged)='onClientChangeSearch($event)'
        [itemTemplate]="itemTemplate1"
        [notFoundTemplate]="notFoundTemplate1"
        placeholder="Category">
      </ng-autocomplete>

      <ng-template #itemTemplate1 let-item>
        <a [innerHTML]="item.categoryName"></a>
      </ng-template>

      <ng-template #notFoundTemplate1 let-notFound>
        <div [innerHTML]="notFound"></div>
      </ng-template>

    </div>

  </div>
  <div class="modal-footer">
    <input (click)="closeUpdate()" type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
    <input [disabled]="!clientValue || !categoryValue" type="submit" class="btn btn-primary"
           value="Add" (click)="addItem()">
  </div>
  <div>
    <table class="display table table-striped table-bordered search-api">
      <thead>
      <tr>
        <th>Client</th>
        <th>Category</th>
        <th></th>
      </tr>
      </thead>
      <tbody>
      <tr *ngFor="let list of updateData.newsClientCategories; index as i">
        <td>{{list.clientName}}</td>
        <td>{{list.categoryName}}</td>
        <td>
          <a href="javascript:;" (click)="deleteItem(list, i)" class="danger p-0">
            <i class="ft-trash font-medium-3"></i>
          </a>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</form>

