import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AllService} from '../../all.service';
import {ToastrService} from 'ngx-toastr';
import {AppAlert} from '../../common/AppAlert';
import {Text} from '@angular/compiler';


@Component({
  selector: 'text-news-body',
  templateUrl: './text-news.component.html',
  styleUrls: ['./text-news.component.css']
})
export class TextNewsComponent implements OnInit {

  textnews:string = ''

  constructor(public dialogRef: MatDialogRef<TextNewsComponent>,
              private allService: AllService,
              @Inject(MAT_DIALOG_DATA) public appdata: any){
  }

  ngOnInit(): void {

    this.textnews = ''
    this.allService.getTextNews(this.appdata.printMediaId).subscribe((data: any)=>{
      this.textnews = data.textNews;
    })
  }
}
