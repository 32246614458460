import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {CookieService} from 'ngx-cookie-service';
import {environment} from '../../environments/environment';
import {User} from '../admin/user/user';

@Injectable({
  providedIn: 'root'
})
export class ManageuserService {

  constructor(private Http: HttpClient,
              private cookieService: CookieService) {
  }

  addUser(data: User) {
    return this.Http.post(environment.baseUrl + '/register', data);
  }

  isUseralreadyTaken(username:string){
    return this.Http.post(environment.baseUrl + '/useravailable',username)
  }

  getAllUsers(sortby: string , direction: string,page:number) {
    return this.Http.get(environment.baseUrl + '/biususers?sortby='+sortby+'&direction='+direction+'&page='+page);
  }

  deleteUser(userid:any){
    return this.Http.delete(environment.baseUrl + '/biususers/'+ userid);
  }
}
