  import { Injectable } from '@angular/core';
  import {environment} from '../environments/environment';
  import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
  import {Observable} from 'rxjs';
  import {CookieService} from 'ngx-cookie-service';


  @Injectable({
    providedIn: 'root'
  })
  export class AllService {

  constructor(private Http: HttpClient,private cookieService: CookieService) { }
    //Ok
    getClients(searchVal: any) {
      return this.Http.get(environment.baseUrl + '/archive/search/client?cn=' + searchVal);
    }

    //Ok
    getPublications(searchVal: any) {
      return this.Http.get(environment.baseUrl + '/archive/search/publication?q=' + searchVal);
    }

    getEdition(publicationId:string,searchVal: any) {
      return this.Http.get(environment.baseUrl + '/archive/search/edition/'+ publicationId +'?q=' + searchVal);
    }

    getCategory(searchVal: any, clientNo: any) {
    return this.Http.get(environment.baseUrl + '/app-master-service/api/categories/v1/' + clientNo + '?cn=' + searchVal);
  }

  getOpinionMaker(searchVal: any) {
    return this.Http.get(environment.baseUrl + '/app-master-service/api/opinionmakers/v1?cn=' + searchVal);
  }

  getSocialMediaData(title: string,searchval2: string ,page: any , sortby: string , direction: string,client: string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/tweets/v3?tweet=' + title + '&page=' + page+ '&clientid=' + client+ '&searchval2=' + searchval2+ '&sortby=' + sortby+ '&direction=' + direction);
  }

  getUserActivities(page: any,userid:string,activity:string,fromdate:string , todate:string) {
    if(userid!="0")
      return this.Http.get(environment.baseUrl + '/print-media-service/api/manageuser/v1/'+ userid +'?activity=' + activity + '&page=' + page + '&fromdate='+fromdate+ '&todate='+todate);
    else
      return this.Http.get(environment.baseUrl + '/print-media-service/api/manageuser/v1?activity=' + activity + '&page=' + page + '&fromdate='+fromdate+ '&todate='+todate);
  }

    getUserActivityReport(page: any,userid:string,activity:string,fromdate:string , todate:string) {
        return this.Http.get(environment.baseUrl + '/print-media-service/api/printmedia/v1/audit-report/',{
          responseType: 'blob'
        });
    }


    getTwitterReport(title: string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/twitter-report/v1/download');
  }

  getYoutubeMediaData(title: string, page: any) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/youtube/v1?title=' + title + '&page=' + page);
  }

  getFacebookMediaData(title: string,searchval2: string ,page: any , sortby: string , direction: string,client: string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/facebook/v3?title=' + title + '&page=' + page+ '&clientid=' + client+ '&searchval2=' + searchval2+ '&sortby=' + sortby+ '&direction=' + direction);
  }

  getGoogleNewsFeeds(title: string,searchval2: string ,page: any , sortby: string , direction: string,client: string,fromdate:string,todate:string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/google-news/v1?text=' + title + '&page=' + page+ '&' +
      'clientid=' + client+ '&searchval2=' + searchval2+ '&sortby=' + sortby+ '&' +
      'direction=' + direction+ '&fromdate=' + fromdate+ '&todate=' + todate);
  }

  getYoutubeFeeds(title: string,searchval2: string ,page: any , sortby: string , direction: string,client: string,fromdate:string,todate:string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/youtube/v2?text=' + title + '&page=' + page+ '&' +
      'clientid=' + client+ '&searchval2=' + searchval2+ '&sortby=' + sortby+ '&' +
      'direction=' + direction+ '&fromdate=' + fromdate+ '&todate=' + todate);
  }

  //Ok
  getTodaysPrintMediaNewsFeeds(title: string,sortby: string , direction: string) {
    return this.Http.get(environment.baseUrl + '/today-media?text=' + title + '&sortby=' + sortby+ '&direction=' + direction);
  }

  getTodaysPrintMediaNewsFeedsAdvanced(publication: string,edition: string,client: string,title: string,sortby: string , direction: string) {
    return this.Http.get(environment.baseUrl + '/print-media-service/api/printmedia/v2?publication=' + publication + '&edition=' + edition + '&client=' + client + '&text=' + title + '&sortby=' + sortby+ '&direction=' + direction);
  }

  //Ok
  getArchivePrintMediaNewsFeeds(fromdate:string,todate:string,title: string,sortby: string , direction: string,page:number) {
    return this.Http.get(environment.baseUrl + '/archive/'+ fromdate +'/'+ todate +'/?text=' + title + '&sortby=' + sortby+ '&direction=' + direction+ '&page=' + page);
  }

  //Ok
  getArchivePrintMediaNewsFeedsAdvanced(fromdate:string,todate:string,publication: string,edition: string,client: string,title: string,sortby: string , direction: string,page:number) {
    return this.Http.get(environment.baseUrl + '/archive/'+ fromdate +'/'+ todate +'?publication=' + publication + '&edition=' + edition + '&client=' + client + '&text=' + title + '&sortby=' + sortby + '&direction=' + direction+ '&page=' + page);
  }
  getTextNews(printmediaid: string) {
    return this.Http.get(environment.baseUrl + '/print-media-service/api/printmedia/v1/text/'+printmediaid);
  }

  mergeTodaysPrintMediaNewsFeeds(mediaids:string,client: string,iscurrent:boolean ) {
    return this.Http.get( environment.baseUrl +'/today-media/file-download?fileids='+ mediaids+"&iscurrent="+iscurrent, {
      responseType: 'blob'
    });
  }

    downloadBundledZipFile(mediaids:string,client: string,iscurrent:boolean) {
    return this.Http.get( environment.baseUrl +'/today-media/zip-download?fileids='+ mediaids+'&iscurrent='+ iscurrent, {
      responseType: 'blob'
    });
  }

    getGoogleNewsFeedsReport(title: string,searchval2: string ,client: string,fromdate:string,todate:string) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/google-news/v1/report?text=' + title + '&clientid=' + client+ '&searchval2=' + searchval2+ '&fromdate=' + fromdate+ '&todate=' + todate);
  }

  getKeywordsDetails(clientid: string,page: number) {
    return this.Http.get(environment.baseUrl + '/social-media-service/api/keyword/v1/clientmetadata/'+clientid+'?pageno=' + page);
  }

    updateSocialMedia(data: any) {
    return this.Http.put(environment.baseUrl + '/social-media-service/api/tweets/v2', data);
  }
  updateYoutubeMedia(data: any) {
    return this.Http.put(environment.baseUrl + '/social-media-service/api/youtube/v2', data);
  }

  updateFacebookMedia(data: any) {
    return this.Http.put(environment.baseUrl + '/social-media-service/api/facebook/v2', data);
  }

  deleteKeyword(keywords:any) {
    return this.Http.delete(environment.baseUrl + '/social-media-service/api/keyword/v1/clientmetadata/'+keywords);
  }

    deleteSocialMediaData(id:any) {
    return this.Http.delete(environment.baseUrl + '/social-media-service/api/tweets/v2/remove_feed/'+id);
  }

  deleteGooleNewsData(id:any) {
    return this.Http.delete(environment.baseUrl + '/social-media-service/api/google-news/v2/remove_feed/'+id);
  }

  blockNewsPublication(publication:string) {
    return this.Http.post(environment.baseUrl + '/social-media-service/api/google-news/v2/block-publication/', publication);
  }

  deleteFacebookMediaData(id:any) {
    return this.Http.delete(environment.baseUrl + '/social-media-service/api/facebook/v2/remove_feed/'+id);
  }

  deleteYoutubeMedia(id:any) {
    return this.Http.delete(environment.baseUrl + '/social-media-service/api/youtube/v2/remove_feed/'+id);
  }

  addKeyword(data: any) {
    return this.Http.post(environment.baseUrl + '/social-media-service/api/keyword/v1/clientmetadata', data);
  }

  ///TODO These RSS would be removed in future as these are not required as of now

  getOnlineMediaData(title: string, page: any) {
    return this.Http.get(environment.baseUrl + '/rss-service/api/todaysrss/v1?title=' + title + '&page=' + page);
  }

  updateOnlineMedia(data: any) {
    return this.Http.put(environment.baseUrl + '/rss-service/api/todaysrss/v2', data);
  }

  deleteOnlineMediaMapping(id:any) {
    return this.Http.delete(environment.baseUrl + '/rss-service/api/todaysrss/v2/remove_mapping/'+id);
  }

  deleteOnlineMediaData(id:any) {
    return this.Http.delete(environment.baseUrl + '/rss-service/api/todaysrss/v2/remove_news/'+id);
  }
}
