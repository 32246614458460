import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AllService} from '../../all.service';
import {ToastrService} from "ngx-toastr";


@Component({
  selector: 'app-dialog-body',
  templateUrl: './facebook-dialog-body.component.html',
  styleUrls: ['./facebook-dialog-body.component.scss']
})
export class FacebookDialogBodyComponent implements OnInit {

  abc: any;

  updateData: any;
  updateData1: any;
  keyword = 'clientName';
  keywordCategory = 'categoryName';
  keywordOpinionMaker = 'opinionMakerName';

  clientValue: any;
  categoryValue: any;
  opinionMakerValue: any;
  /*data = [
    {
      id: 1,
      name: 'Usa'
    },
    {
      id: 2,
      name: 'England'
    }
  ];*/
  data: any;
  dataCategory: any;
  dataOpinionMaker: any;

  constructor(public dialogRef: MatDialogRef<FacebookDialogBodyComponent>,
              @Inject(MAT_DIALOG_DATA) public data1: any,
              private allService: AllService,
              private toastr: ToastrService) {
  }

  ngOnInit(): void {
    this.updateData = this.data1;
    console.log(this.updateData);

  }

  close() {
    this.dialogRef.close();
    window.location.reload()
  }

  selectEvent(item: any) {
    // do something with selected item
    this.clientValue = item.clientId;
  }

  selectCategoryEvent(item: any) {
    // do something with selected item
    this.categoryValue = item.categoryName;
  }

  selectOpinionMakerEvent(item: any) {
    // do something with selected item
    this.opinionMakerValue = item.opinionMakerName;
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.allService.getClients(val).subscribe((data: any) => {
      this.data = data;
      console.log(data);
    });
  }

  onClientChangeSearch(val: string) {
    this.categoryValue = val;

    this.allService.getCategory(val, this.clientValue).subscribe((data: any) => {
      this.dataCategory = data;
      console.log(data);
    });
  }

  onOpinionMakerChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
    this.allService.getOpinionMaker(val).subscribe((data: any) => {
      this.dataOpinionMaker = data;
      console.log(data);
    });
  }

  addItem() {

    // this.updateData1 = this.updateData;
    let copyObj = JSON.parse(JSON.stringify(this.updateData))
    copyObj.clientMetaData.push({
      category: this.categoryValue,
      client: this.clientValue,
      opinionMaker: this.opinionMakerValue
    })

    this.allService.updateFacebookMedia(copyObj).subscribe((data: any) => {
      console.log(data);
      this.updateData.clientMetaData.push({
        category: this.categoryValue,
        client: this.clientValue,
        opinionMaker: this.opinionMakerValue,
        id: data.id
      })
    })
  }

  deleteItem(obj: any, index:number) {

    let copyObj = JSON.parse(JSON.stringify(this.updateData))
    copyObj.clientMetaData.splice(index, 1);

    this.allService.updateFacebookMedia(copyObj).subscribe((data: any) => {
      console.log(data);
      this.toastr.success("Mapping Deleted..!!", "Success")
      this.updateData.clientMetaData.splice(index, 1);
    })
  }

}
