<div class="body_wrapper">
  <div class="center">
    <div class="header_area">
      <div class="logo floatleft"><a href="#"><img src="../../assets/images/logo.jpg" alt="" /></a></div>
      <div class="top_menu floatleft">
        <ul>
          <li><a href="#/home">Home</a></li>
          <li><a href="#">About</a></li>
          <li><a href="#">Contact us</a></li>
          <li><a href="#">Subscribe</a></li>
          <li><a href="#/login">Login</a></li>
        </ul>
      </div>
      <div class="social_plus_search floatright">
        <div class="social">
          <ul>
            <li><a href="#" class="twitter"></a></li>
            <li><a href="#" class="facebook"></a></li>
            <li><a href="#" class="feed"></a></li>
          </ul>
        </div>
        <div class="search">
          <form action="#" method="post" id="search_form">
            <input type="text" value="Search news" id="s" />
            <input type="submit" id="searchform" value="search" />
            <input type="hidden" value="post" name="post_type" />
          </form>
        </div>
      </div>
    </div>
<!--    <div class="main_menu_area">-->
<!--      <ul id="">-->
<!--        <li><a href="#" >H-Clips</a>-->
<!--&lt;!&ndash;          <ul>&ndash;&gt;-->
<!--&lt;!&ndash;            <li><a href="#">Today's News</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <ul>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;              <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            </ul>&ndash;&gt;-->

<!--&lt;!&ndash;            <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li><a href="#">Single item</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;          </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--        <li><a href="#">E-Clips</a></li>-->
<!--        <li><a href="#">Media-Buzz</a>-->
<!--&lt;!&ndash;          <ul>&ndash;&gt;-->
<!--&lt;!&ndash;            <li><a href="#">Facebook</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li><a href="#">Twitter</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;            <li><a href="#">Youtube</a></li>&ndash;&gt;-->
<!--&lt;!&ndash;          </ul>&ndash;&gt;-->
<!--&lt;!&ndash;        </li>&ndash;&gt;-->
<!--        <li><a href="#">Web-Mon</a></li>-->
<!--        <li><a href="#">MER</a></li>-->
<!--        <li><a href="#">Web Clippings</a></li>-->
<!--        <li><a href="#">Online Archives</a></li>-->
<!--        <li><a href="#">EMM</a></li>-->
<!--      </ul>-->
<!--    </div>-->
    <div class="slider_area">
      <div class="slider">
        <ul class="bxslider">
          <li><img src="../../assets/images/header.jpg" alt="" title="Slider caption text" /></li>
          <li><img src="../../assets/images/header.jpg" alt="" title="Slider caption text" /></li>
          <li><img src="../../assets/images/3.jpg" alt="" title="Slider caption text" /></li>
        </ul>
      </div>
    </div>
    <div class="content_area">
      <div class="main_content floatleft">
        <div class="left_coloum floatleft">
          <div class="single_left_coloum_wrapper">
            <h2 class="title">FROM   AROUND   THE   WORLD</h2>
            <a class="more" href="#">more</a>
            <div class="single_left_coloum floatleft" *ngFor="let wn of this.worldData.slice(0,3)">
              <img src={{wn.news_image}} alt="" />
              <h3 style="margin-top: 10px">{{wn.title.substring(0,40)}}</h3>
              <p>{{wn.desc.substring(0.60)}}</p>
              <a class="readmore" href="{{wn.url}}"  target="_blank" >read more</a> </div>


          </div>
          <div class="single_left_coloum_wrapper">
            <h2 class="title">SPORTS NEWS</h2>
            <a class="more" href="#">more</a>
            <div class="single_left_coloum floatleft" *ngFor="let wn of this.sportsNews.slice(0,3)">
              <img  src="{{wn.news_image}}" alt="" />
              <h3 >{{wn.title}}</h3>
              <p>{{wn.desc.substring(0,60)}}</p>
              <a class="readmore" href={{wn.url}}  target="_blank">read more</a> </div>

          </div>
          <div class="single_left_coloum_wrapper">
            <h2 class="title">BUSINESS NEWS</h2>
            <a class="more" href="#">more</a>
            <div class="single_left_coloum floatleft" *ngFor="let wn of this.politicsNews.slice(0,3)">
              <img src={{wn.news_image}} alt="" />
              <h3 style="margin-top: 10px">{{wn.title.substring(0,40)}}</h3>
              <p>{{wn.desc.substring(0,80)}}</p>
              <a class="readmore" href="{{wn.url}}"  target="_blank" >read more</a> </div>
          </div>
<!--          <div class="single_left_coloum_wrapper single_cat_left">-->
<!--            <h2 class="title">TECH NEWS</h2>-->
<!--            <a class="more" href="#">more</a>-->
<!--            <div class="single_cat_left_content floatleft" *ngFor="let wn of this.techNews.slice(0,4)" >-->
<!--              <h3>{{wn.title}} </h3>-->
<!--              <p>{{wn.desc}}</p>-->

<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="right_coloum floatright">
          <div class="single_right_coloum">
            <h2 class="title">FROM THE CEO</h2>
            <ul>
              <li>
                <div class="single_cat_right_content">
                  <h3>We welcome you to the new look of our website</h3>
                  <p>We are in the business of NEWS and have strived for providing the news that matters to you everyday.</p>
                  <p>Our new Home Page bring to you news (beyond what is your business) from other areas of your interest</p>
                  <p>We at BIUS continuously strive to improve what we do. Please do give us your feedback that will help us serve you better.</p>
                  <p class="single_cat_right_content_meta"><a href="#"><span></span></a></p>
                </div>
              </li>

            </ul>
             </div>
          <div class="single_right_coloum">
            <h2 class="title">TECH NEWS</h2>
            <div class="single_cat_right_content editorial" *ngFor="let wn of this.techNews.slice(0,4)" >
              <a href="{{wn.url}}" target="_blank">
              <img src="{{wn.news_image}}" alt="" />
              <h3>{{wn.title}}</h3>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="sidebar floatright">


        <div class="single_sidebar">
          <div class="popular">
            <h2 class="title">India's Top 20</h2>
            <ul>
              <li *ngFor="let indnews of this.indiaNews.slice(0,10)">
                <div class="single_popular">
                  <p>{{indnews.posted_date}}</p>
                  <h3>{{indnews.title}} <a href="{{indnews.url}}" target="_blank" class="readmore">Read More</a></h3>
                </div>
              </li>
            </ul>
            <a class="popular_more">more</a> </div>
        </div>
<!--        <div class="single_sidebar"> <img src="../../assets/images/add1.png" alt="" /> </div>-->
        <div class="single_sidebar">
<!--          <h2 class="title">ADD</h2>-->
<!--          <img src="../../assets/images/add2.png" alt="" /> </div>-->
      </div>
    </div>
    <div class="footer_top_area">
      <div class="row">
        <div class="col-4">
        <h2>Contact BIUS</h2>
        <ul class="f_menu1">
          <li><a href="#">9811295013</a></li>
          <li><a href="#">rohit@biusnews.com</a></li>
          <li ><a href="#">Located in Delhi at </a>
          <li ><a href="#">504, Siddharth Building,96 Nehru Place</a></li>
          <li ><a href="#">New Delhi- 110019</a></li>

        </ul>
        </div>
        <div class="col-4">
          <h2>Products</h2>
          <ul class="f_menu1">
            <li class="f_margin"><a href="#">E-Clips</a></li>
            <li class="f_margin"><a href="#">H-Clips</a></li>
            <li><a href="#">Media-Buzz</a>
            <li><a href="#">Web-Mon</a></li>
            <li><a href="#">MER</a></li>
            <li><a href="#">Web Clippings</a></li>
            <li><a href="#">Online Archives</a></li>
            <li><a href="#">EMM</a></li>
          </ul>
        </div>
        <div class="col-4">
          <h2>Our Coverage</h2>
          <ul class="f_menu1">
            <li><a href="#">Ahmedabad, Agra, Ambala,Bangalore, </a></li>
            <li><a href="#">Bharatpur, Bhillai, Bhopal, Bilaspur,</a></li>
            <li><a href="#">Chamba, Chandigarh, Chattarpur, Chennai</a>
            <li><a href="#">Dehradun, Delhi,Guwahati, Gwalior,</a></li>
            <li><a href="#">Hissar, Hyderabad, Indore, Jabalpur, Jaipur,</a></li>
            <li><a href="#">Jammu, Jamshedpur, Jhansi, Kanpur, </a></li>
            <li><a href="#">Kochi, Kolkata, Kota, Lucknow, Meerut,  </a></li>
            <li><a href="#">Mumbai, Nagpur, Nainital,Patna, Pune,</a></li>
            <li><a href="#">Ranchi, Raipur, Shillong, Shimla</a></li>
            <li><a href="#">Trivandrum</a></li>


          </ul>
        </div>
      </div>
    </div>


  </div>
</div>
</div>
