import {Component, OnInit, Inject} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-news-desc-archieve',
  templateUrl: './news-desc-archieve.component.html',
  styleUrls: ['./news-desc-archieve.component.scss']
})
export class NewsDescArchieveComponent implements OnInit {

  descData: any;

  constructor(public dialogRef: MatDialogRef<NewsDescArchieveComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.descData = this.data;
  }

  close() {
    this.dialogRef.close();
    // window.location.reload();
  }

}
