<div class=" tableFixHead main-content">
  <div class=" content-wrapper">
    <!--Extended Table starts-->

    <section id="extended">

      <div class="card card-primary">
        <div class="row">
          <div class="col-3 offset-1">
            <input type="text" class="form-control" [(ngModel)]="searchVal" (keyup.enter)="page=1; getTodaysPrintMediaNews(); "
                   name="search" style="margin-left: 0px" placeholder="Search..">
          </div>


          <div class="col-1" style="padding-top: 8px">
            <i class="fa fa-download merge-pdf" title="Download Merged PDF"   aria-hidden="false" (click)="getMerged()" ></i>
            <i class="fa fa-file-zip-o download-zip" title="Download zip file with XML"  aria-hidden="false" (click)="downloadZip()" ></i>
            <i class="fa fa-refresh refresh-icon" title="Refresh your feeds"  aria-hidden="false" (click)="getTodaysPrintMediaNews()"></i>
            <i class="fa fa-cog fa-spin fa-2x fa-fw refresh-icon"  *ngIf="this.isLoading"></i>
            <i class="fa fa-search" style=" cursor: pointer; color: #9F0053" title="Advance Search" data-toggle="modal" (click)="openSearchDialog({isCurrent:this.iscurrent})"></i>
            <i class="fa fa-eraser fa-1x" style="padding-left: 10px; cursor: pointer; color: #9F0053" title="Clear Search Criteria and refresh"  aria-hidden="false" (click)="getTodaysPrintMediaNews()"></i>
            <span class="sr-only">Loading...</span>

          </div>
        </div>

        <div class="card-body align-items-center">

          <table class=" table table-striped table-responsive table-hover text-center m-0" style="border-radius: 20px !important;">
          <thead class="">
          <tr class=" table-primary" >
            <th scope="col"  style="width: 1%">#</th>
            <th *ngIf="userType == 'ADMIN'" scope="col" style="width: 15%;cursor: pointer" (click)="this.sort('clientName')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='clientName'" ></i>
              Client
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='clientName'" ></i>
            </th>
            <th scope="col" style="width: 4%">News Date</th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('publication')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='publication'" ></i>
              Publication
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='publication'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('city')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='city'" ></i>
              Edition
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='city'" ></i>
            </th>
            <th scope="col" style="width: 10%;text-align: left;cursor: pointer" (click)="this.sort('category')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='category'" ></i>
              Category
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='category'" ></i>
            </th>
            <th scope="col">Headline</th>
            <th scope="col">Page</th>
            <th scope="col">Download</th>
            <th scope="col">Text</th>
          </tr>
          </thead>
          <tbody *ngIf="todaysPrintMediaNewsList">
          <tr style="width: 100%"
            *ngFor="let list of todaysPrintMediaNewsList| paginate: { itemsPerPage: 80, currentPage: page, totalItems: totalCount};let ndx = index">

            <td>{{ndx + 1}}</td>
            <td *ngIf="userType == 'ADMIN'">{{list.clientName}}</td>
            <td>{{list.formattedImageDate}}</td>
            <td style="width: 10%;text-align: left">{{list.publication}}</td>
            <td style="width: 10%">{{list.city}}</td>
            <td style="width: 10%;text-align: left">{{list.category}}</td>
            <td align="left" style="width: 55%"> <a  href="{{list.imagePath}}" innerHTML="{{list.headline.substr(0,250) | highlight: searchVal}}" target="_blank"></a></td>
            <td>{{list.pageNo}}</td>
            <td><input type="checkbox" [(ngModel)]="list.is_selected" value="{{list.printMediaId}}"/></td>
            <td><i class="fa fa-bars" style=" cursor: pointer; color: #9F0053" data-toggle="modal" (click)="openDialog(list)"></i></td>
          </tr>
          </tbody>
        </table>



      </div>
        </div>

      <div *ngIf="!this.iscurrent">
        <pagination-controls (pageChange)="page = $event; getTodaysPrintMediaNews()" style="margin-left: 30%"></pagination-controls>
      </div>

    </section>
    <!--Extended Table Ends-->

  </div>
</div>
