<div class="main-content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <!--Statistics cards Starts-->
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card gradient-purple-love" (click)="gotoTwitterFeeds()" style="cursor: pointer" >
          <div class="card-content" >
            <div class="card-body py-0">
              <div class="media pb-1">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 white mb-0" id="tw1">-</h3>
                  <span>Twitter - Last 7 days</span>
                </div>
                <div class="media-right white text-right">
                  <i class="ft-twitter font-large-1"></i>
                </div>
              </div>
            </div>
            <div id="Widget-line-chart" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
            </div>
          </div>
        </div>
      </div >
      <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card gradient-ibiza-sunset" (click)="gotoYoutubeFeeds()" style="cursor: pointer">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 white mb-0" id="yt1">-</h3>
                  <span>Youtube - Last 7 days</span>
                </div>
                <div class="media-right white text-right">
                  <i class="ft-youtube font-large-1"></i>
                </div>
              </div>
            </div>
            <div id="Widget-line-chart1" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
            </div>

          </div>
        </div>
      </div>

      <div [ngClass]="[roles.includes('PRINT') && !roles.includes('SOCIAL')? 'col-xl-12' : 'col-xl-3']" >
        <div class="card gradient-ibiza-sunset" (click)="gotoTodaysPrintMedia()" style="cursor: pointer">
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 white mb-0" id="pm1">-</h3>
                  <span>Last 7 days Analysis</span>
                </div>
                <div class="media-right white text-right">
                  <i class="ft-message-circle font-large-1"></i>
                </div>
              </div>
            </div>
            <div id="Widget-line-chart2" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card gradient-king-yna" (click)="gotoFacebookFeeds()" style="cursor: pointer" >
          <div class="card-content">
            <div class="card-body py-0">
              <div class="media pb-1">
                <div class="media-body white text-left">
                  <h3 class="font-large-1 white mb-0" id="fb1">-</h3>
                  <span>Facebook - Last 7 days</span>
                </div>
                <div class="media-right white text-right">
                  <i class="ft-facebook font-large-1"></i>
                </div>
              </div>
            </div>
            <div id="Widget-line-chart3" class="height-75 WidgetlineChart WidgetlineChartshadow mb-2">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--Statistics cards Ends-->


    <!--Line with Area Chart 1 Starts-->
    <div class="row">
      <div class="col-6" *ngIf="roles.includes('SOCIAL')">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Social Media News Analysis</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div class="chart-info mb-3 ml-3">
                <span class="gradient-purple-bliss d-inline-block rounded-circle mr-1"
                      style="width:15px; height:15px;"></span>
                Twitter
                <span class="gradient-mint d-inline-block rounded-circle mr-1 ml-2"
                      style="width:15px; height:15px;"></span>
                Facebook
                <span class="gradient-pomegranate d-inline-block rounded-circle ml-3"
                      style="width:15px; height:15px;"></span>
                Youtube
              </div>
              <div id="line-area" class="height-350 lineArea">
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" *ngIf="roles.includes('SOCIAL')">
        <div class="card">
          <div >
            <div class="card-body">
              <div id="line-area1" class="height-355 lineArea">
                <img *ngIf="userType != 'ADMIN'" style="height: 445px; width: 1085px" src="{{this.img_path}}">
                <img *ngIf="userType == 'ADMIN'" style="height: 445px; width: 1085px" src="https://bius-mails.s3.ap-south-1.amazonaws.com/img/wordcloud-admin.png">
              </div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="col-6">-->
<!--        <div class="card">-->
<!--          <img src="https://bius-mails.s3.ap-south-1.amazonaws.com/img/wordcloud.png">-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!--Line with Area Chart 1 Ends-->

    <div class="row match-height">

    </div>


    <div class="row match-height" >
      <div class="col-xl-4 col-md-4 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Statistics</h4>
          </div>
          <div class="card-content">

            <p class="font-medium-2 text-center mb-0 mt-2">News Counts in last 7 days</p>
            <div id="bar-chart" class="height-250 BarChartShadow BarChart">
            </div>

            <div class="card-body">
              <div class="row">
                <div class="col text-center">
                  <span class="gradient-starfall d-block rounded-circle mx-auto mb-2"
                        style="width:10px; height:10px;"></span>
                  <span class="font-medium-4 d-block mb-2" id="twstat1">0</span>
                  <span class="font-small-3">Twitter</span>
                </div>
                <div class="col text-center">
                  <span class="gradient-mint d-block rounded-circle mx-auto mb-2"
                        style="width:10px; height:10px;"></span>
                  <span class="font-medium-4 d-block mb-2" id="fbstat1">0</span>
                  <span class="font-small-3">Facebook</span>
                </div>

                <div class="col text-center">
                  <span class="gradient-ibiza-sunset d-block rounded-circle mx-auto mb-2"
                        style="width:10px; height:10px;"></span>
                  <span class="font-medium-4 d-block mb-2" id="ytstat1">0</span>
                  <span class="font-small-3">Youtube</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-md-4 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title mb-0">Top 5 trending tweets</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div *ngIf="todaySocialMediaList">
                <div class="media pt-0 pb-2"  *ngFor="let list of todaySocialMediaList.tweets.slice(0,5) ">
                  <!--                <img class="mr-3 avatar" src="../../assets/app-assets/img/portrait/small/avatar-s-12.png" alt="Avatar"-->
                  <!--                     width="35">-->
                  <i class="fa fa-twitter" style="padding-top: 7px;margin-right:10px;font-size: 20px;color: #00b3ff"></i>
                  <div class="media-body">
                    <h4 class="font-medium-1 mb-0"><a class="headline" href="{{'https://twitter.com' + list.tweetUrl}}"
                                                      innerHTML="{{list.tweet.substr(0,80) | highlight: searchVal}}" target="_blank"></a></h4>
                    <p class="text-muted font-small-3 m-0">{{list.tweetedBy}}&nbsp;<i class="fa fa-heart" style="padding-right:120px;font-size: 20px;color: #ed1e79;"><span style="font-size: 13px;font-family: Bahnschrift">&nbsp;{{list.likesCount}}</span></i></p>
                  </div>

                </div>

              </div>


            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-12" *ngIf="roles.includes('SOCIAL')">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Sentiment Analysis - Social</h4>
          </div>
          <div class="card-content">

            <p class="font-medium-2 text-center mb-0 mt-2"></p>
            <div id="donut-dashboard-chart" class="height-250 donut">
            </div>

            <div class="card-body">
              <div class="row mb-3">
                <div class="col">
                  <span class="mb-1 text-muted d-block" id="se-neutral">0% - Neutral</span>
                  <div class="progress" style="height: 5px;">
                    <div class="progress-bar bg-info" role="progressbar" id="se-neutral-value" style="width: 0%;" aria-valuenow="0"
                         aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="col">
                  <span class="mb-1 text-muted d-block" id="se-positive">0% - Positive</span>
                  <div class="progress" style="height: 5px;">
                    <div class="progress-bar bg-success" role="progressbar" id="se-positive-value" style="width: 0%;" aria-valuenow="0"
                         aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col">
                  <span class="mb-1 text-muted d-block" id="se-negative">0% - Negative</span>
                  <div class="progress" style="height: 5px;">
                    <div class="progress-bar bg-primary" role="progressbar" id="se-negative-value" style="width: 0%;" aria-valuenow="0"
                         aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div class="col">
                  <span class="mb-1 text-muted d-block" id="se-inprogress">0% - In Progress</span>
                  <div class="progress" style="height: 5px;">
                    <div class="progress-bar bg-warning" role="progressbar" id="se-inprogress-value" style="width: 0%;" aria-valuenow="0"
                         aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Today's Print Media Tonality</h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div id="pie-chart" class="d-flex justify-content-center"></div>
            </div>
          </div>
        </div>
      </div>
<!--      <div class="col-xl-6 col-12">-->
<!--        <div class="card">-->
<!--          <div class="card-header">-->
<!--            <h4 class="card-title">Sentiment Analysis - Print</h4>-->
<!--          </div>-->
<!--          <div class="card-content">-->

<!--            <p class="font-medium-2 text-center mb-0 mt-2"></p>-->
<!--            <div id="donut-dashboard-chart-print" class="height-250 donut">-->
<!--            </div>-->

<!--            <div class="card-body">-->
<!--              <div class="row mb-3">-->
<!--                <div class="col">-->
<!--                  <span class="mb-1 text-muted d-block" id="se-neutral-print">0% - Neutral</span>-->
<!--                  <div class="progress" style="height: 5px;">-->
<!--                    <div class="progress-bar bg-info" role="progressbar" id="se-neutral-value-print" style="width: 0%;" aria-valuenow="0"-->
<!--                         aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col">-->
<!--                  <span class="mb-1 text-muted d-block" id="se-positive-print">0% - Positive</span>-->
<!--                  <div class="progress" style="height: 5px;">-->
<!--                    <div class="progress-bar bg-success" role="progressbar" id="se-positive-value-print" style="width: 0%;" aria-valuenow="0"-->
<!--                         aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--              <div class="row mb-2">-->
<!--                <div class="col">-->
<!--                  <span class="mb-1 text-muted d-block" id="se-negative-print">0% - Negative</span>-->
<!--                  <div class="progress" style="height: 5px;">-->
<!--                    <div class="progress-bar bg-primary" role="progressbar" id="se-negative-value-print" style="width: 0%;" aria-valuenow="0"-->
<!--                         aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div class="col">-->
<!--                  <span class="mb-1 text-muted d-block" id="se-inprogress-print">0% - In Progress</span>-->
<!--                  <div class="progress" style="height: 5px;">-->
<!--                    <div class="progress-bar bg-warning" role="progressbar" id="se-inprogress-value-print" style="width: 0%;" aria-valuenow="0"-->
<!--                         aria-valuemin="0" aria-valuemax="100"></div>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
      <!-- Bar Chart starts -->
      <div class="col-xl-6 col-12">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Edition wise Today's Print </h4>
          </div>
          <div class="card-content">
            <div class="card-body">
              <div id="barChart_print"></div>
            </div>
          </div>
        </div>
      </div>

      <!-- Bar Chart ends -->
    </div>

  </div>
</div>
