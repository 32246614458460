import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {GoogleNewsDialogBodyComponent} from './dialog-body/google-dialog-body.component';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import {Util} from '../common/Util';
import {Google} from './google';


@Component({
  selector: 'app-social-media',
  templateUrl: './google-news.component.html',
  styleUrls: ['./google-news.component.scss']
})
export class GoogleNewsComponent implements OnInit {

  todaySocialMediaList: Google[]=[];
  searchVal: string;
  searchVal2: string='';
  page:number = 1;
  sortDirection: string = 'posted_timestamp';
  columnName: string = 'posted_timestamp';
  searchClient: string = '';
  isLoading:boolean = false;
  selectAll:boolean = false;
  fromdate:string='';
  todate:string='';

  selectedFeedids:string = ''

  clientList = [
    {clientId:"",clientName:"--All--",selected:true}
  ]
  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;
  changeText:boolean = false;
  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              public util:Util
  ) {


    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
    this.searchVal2 = 'L24H';
  }


  sort(column:string) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.columnName = column;
    this.getTodaySocialMediaData();
  }

  ngOnInit(): void {
    this.getClientAttachedToAdmin();
    this.getTodaySocialMediaData();
    this.searchVal2 = 'L24H';
  }
  getClientAttachedToAdmin():any{
    if(this.userType=="ADMIN"){
      this.allService.getClients("").subscribe((data: any) => {
        this.clientList = [...this.clientList,...data];
      });

    }
  }
  getTodaySocialMediaData(): void {


    this.isLoading=true;
    this.allService.getGoogleNewsFeeds(this.searchVal,this.searchVal2,this.page,this.columnName,this.sortDirection,this.searchClient,this.fromdate,this.todate).subscribe((data: any) => {
      console.log(data);
      this.todaySocialMediaList = data.googleNews;
      this.isLoading=false;
      this.totalCount = data.pages * 20;
    });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(GoogleNewsDialogBodyComponent, dialogConfig);
  }

  selectAllCheckboxes(){

    this.todaySocialMediaList.forEach(item=>{
      item.is_selected = !this.selectAll
    })
  }
  deleteSocialMediaEntries(){
    this.todaySocialMediaList.forEach(item=>{
      if(item.is_selected)
        this.selectedFeedids+=item.id + ","
    })
    const data = {
      id: this.selectedFeedids.substring(0, this.selectedFeedids.length - 1)
    }
    if(this.selectedFeedids==''){
      alert('Please select atleast 1 row.')
      return false;
    }
    this.deleteSocialMediaEntry(data);
    return ;
  }

  blockNewsPublication(data: any) {
    this.allService.blockNewsPublication(data.publication).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.selectedFeedids = '';
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
    );
  }
  deleteSocialMediaEntry(data: any) {
    this.allService.deleteGooleNewsData(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.selectedFeedids = '';
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
    );
  }

  downloadGoogleNewsAsExcel(){
    this.allService.getGoogleNewsFeedsReport(this.searchVal,this.searchVal2,this.searchClient,this.fromdate,this.todate).subscribe((data: any) => {
      this.util.exportAsExcelFile(data,'export-to-excel');
    });
  }
  downloadGoogleNewsAsText(){
    var txt = '';
    this.allService.getGoogleNewsFeedsReport(this.searchVal,this.searchVal2,this.searchClient,this.fromdate,this.todate).subscribe((data: any) => {
      data.forEach((item:any,index:number
        ,arr:[])=>{
        txt+=item.newsDetails + "\n"
      })
      this.util.download(txt,'export-to-text');
    });
  }

}
