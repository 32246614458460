<div class="loading" lang="en" *ngIf="!loginPage" >

  <div class="horizontal-layout horizontal-menu horizontal-menu-padding 2-columns  navbar-sticky" data-open="hover"
       data-menu="horizontal-menu" data-col="2-columns">

    <nav class="navbar navbar-expand-lg navbar-light header-navbar navbar-fixed" id="menu">
      <div class="container-fluid navbar-wrapper">
        <div class="navbar-header d-flex">
          <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
               data-toggle="collapse"><i class="ft-menu font-medium-3"></i></div>
          <ul class="navbar-nav" id="navbar-menu">
            <li class="nav-item mr-2 d-none d-lg-block"><div class="logo"><a class="logo-text"><img src="./assets/images/bius.png"/>
              <!--                    <div class="logo-img"><img class="logo-img" alt="Apex logo"-->
              <!--                                               src="../assets/app-assets/img/logo-dark.png"></div>-->
              <!--                    <span class="text">BIUS </span>-->

            </a></div>
            </li>
            <!--<li class="nav-item nav-search"><a class="nav-link nav-link-search" href="javascript:"><i class="ft-search font-medium-3"></i></a>
              <div class="search-input">
                <div class="search-input-icon"><i class="ft-search font-medium-3"></i></div>
                <input class="input" type="text" placeholder="Explore Apex..."
                       tabindex="0" data-search="template-search">
                <div class="search-input-close"><i class="ft-x font-medium-3"></i></div>
                <ul class="search-list"></ul>
              </div>
            </li>-->
          </ul>
          <div class="pageNavigate navbar-container main-menu-content center-layout" data-menu="menu-container">
            <!-- include ../../../includes/mixins-->

            <ul class="navigation-main nav navbar-nav" id="main-menu-navigation" data-menu="menu-navigation" >
              <li class="dropdown nav-item" appShowclass="show" data-menu="dropdown">
                <a (click)="goToDashboard()" class="dropdown-toggle nav-link d-flex align-items-center"
                   href="javascript:;" data-toggle="dropdown">
                  <i class="ft-home"></i>
                  <span data-i18n="Dashboard">Dashboard</span>
                </a>
              </li>
              <li  appShowclass="show" class="dropdown nav-item" data-menu="dropdown">
                <a class="dropdown-toggle nav-link d-flex align-items-center" href="javascript:;" data-toggle="dropdown"><i
                class="ft-box"></i><span data-i18n="Apps">Online Media</span></a>
                <ul class="dropdown-menu" id="dd-menu">
                  <li data-menu="">
                    <a routerLink="/googleNews" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Online News</span></a>
                  </li>
<!--                  <li data-menu="">-->
<!--                    <a routerLink="/onlineMedia" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>-->
<!--                      <span data-i18n="Email">Todays RSS News</span>-->
<!--                    </a>-->
<!--                  </li>-->
<!--                  <li data-menu="">-->
<!--                    <a routerLink="/onlineMediaArchieve" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>-->
<!--                      <span data-i18n="Chat">Archive RSS News</span>-->
<!--                    </a>-->
<!--                  </li>-->
                </ul>
              </li>

              <li  appShowclass="show" class="dropdown nav-item" data-menu="dropdown">
                <a class="dropdown-toggle nav-link d-flex align-items-center" href="javascript:;" data-toggle="dropdown"><i
                class="ft-aperture"></i><span data-i18n="UI Kit">Social Media</span></a>
                <ul class="dropdown-menu">
                  <li data-menu="">
                    <a routerLink="/socialMedia" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Twitter</span></a>
                  </li>
                  <li data-menu="">
                    <a routerLink="/facebookMedia" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Facebook</span></a>
                  </li>
                </ul>
              </li>

              <li *ngIf="roles.includes('ADMIN')" appShowclass="show" class="dropdown nav-item" data-menu="dropdown">
                <a class="dropdown-toggle nav-link d-flex align-items-center" href="javascript:;" data-toggle="dropdown"><i
                  class="ft-box"></i><span data-i18n="Apps">Admin</span></a>
                <ul class="dropdown-menu">
                  <li data-menu="">
                    <a routerLink="/managekeyword" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Keyword Manage</span></a>
                  </li>
                  <li data-menu="" *ngIf="roles.includes('MANAGER')">
                    <a routerLink="/manageuser" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Manage User</span></a>
                  </li>
                  <li data-menu="" *ngIf="roles.includes('MANAGER')">
                    <a routerLink="/useractivity" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Activity Monitor</span></a>
                  </li>

                </ul>
              </li>
              <li appShowclass="show" class="dropdown nav-item" data-menu="dropdown">
                <a class="dropdown-toggle nav-link d-flex align-items-center" href="javascript:;" data-toggle="dropdown"><i
                  class="ft-box"></i><span data-i18n="Apps">Electronic News</span></a>
                <ul class="dropdown-menu">
                  <li data-menu="">
                    <a routerLink="/youtubeMedia" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Youtube</span></a>
                  </li>
                </ul>
              </li>
              <li  appShowclass="show" class="dropdown nav-item" data-menu="dropdown">
                <a class="dropdown-toggle nav-link d-flex align-items-center" href="javascript:;" data-toggle="dropdown"><i
                  class="ft-box"></i><span data-i18n="Apps">Print Media</span></a>
                <ul class="dropdown-menu">
                  <li data-menu="">
                    <a routerLink="/todaysprintmedia/curr" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Today's Print News</span></a>
                  </li>
                  <li data-menu="">
                    <a routerLink="/todaysprintmedia/arch" class="dropdown-item d-flex align-items-center" data-toggle="dropdown"><i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Email">Archive's Print News</span></a>
                  </li>

                </ul>
              </li>
            </ul>
          </div>
          <div class="navbar-brand-center">
            <div class="navbar-header">
              <ul class="navbar-nav" id="menu-logo">
                <li class="nav-item">
                  <div class="logo"><a class="logo-text"><img src="{{imgUrl}}"/>
<!--                    <div class="logo-img"><img class="logo-img" alt="Apex logo"-->
<!--                                               src="../assets/app-assets/img/logo-dark.png"></div>-->
<!--                    <span class="text">BIUS </span>-->

                  </a></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="pageHeading">{{pageHeading}}

        </div>
        <div class="navbar-container">
          <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
            <ul class="navbar-nav" id="menu-logout">
              <li class="dropdown nav-item mr-1">
                <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
                   href="javascript:;" data-toggle="dropdown">
                  <div class="user d-md-flex d-none mr-2"><span class="text-right">{{username}}</span><span
                    class="text-right text-muted font-small-3">Available</span></div>
                  <img class="avatar" src="../assets/app-assets/img/portrait/small/avatar-s-1.png" alt="avatar"
                       height="35" width="35">
                </a>
                <ul class="dropdown-menu">
                  <li class="active" data-menu="">
                    <a (click)="logout()" class="dropdown-item d-flex align-items-center" data-toggle="dropdown">
                      <i class="ft-arrow-right submenu-icon"></i>
                      <span data-i18n="Home">Logout</span>
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
    <!-- Navbar (Header) Ends-->

    <!-- ////////////////////////////////////////////////////////////////////////////-->
    <div class="wrapper">

      <div class="main-panel">

        <router-outlet></router-outlet>

        <footer class="footer undefined undefined">
          <p class="clearfix text-muted m-0"><span>Copyright &copy; 2021 &nbsp;</span><a href="http://vrisag.com"
                                                                                         id="vrisaglink"
                                                                                         target="_blank">Vrisag</a><span
            class="d-none d-sm-inline-block">, All rights reserved.</span></p>
        </footer>

        <button class="btn btn-primary scroll-top" type="button"><i class="ft-arrow-up"></i></button>

      </div>

    </div>
    <!-- ////////////////////////////////////////////////////////////////////////////-->

    <!-- START Notification Sidebar-->

  </div>

</div>

<div *ngIf="loginPage">
  <router-outlet></router-outlet>
</div>


<style>
  .pageNavigate a i {
    margin: 7px;
    font-weight: bold;
    font-size: 15px;
  }

  .pageNavigate {
    font-weight: bold;
    margin-left: 30px;
  }

  .main-panel {
    margin-top: 4.49rem !important;
  }

  .pageHeading {
    font-size: 20px;
    margin-left: 260px;
    margin-bottom: 7px;
    font-weight: 500;
  }

  .dropdown-menu {
    margin-top: -5px;
  }
</style>
