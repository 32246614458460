<div class=" tableFixHead main-content">
  <div class=" content-wrapper">
    <!--Extended Table starts-->
    <div class="row">
      <div class="col-3" style="padding-top: 8px;margin-right: 85px">
        <input type="text" [(ngModel)]="searchVal" (keyup.enter)="page=1; getTodaySocialMediaData(); "
               name="search" style="margin-left: 0px" placeholder="Search..">
      </div>
      <div class="col-2"  *ngIf="userType == 'ADMIN'" style="padding-top: 8px;margin-right: 65px" >
        <select  [(ngModel)]="this.searchClient" (change)="page=1; getTodaySocialMediaData(); ">
          <option selected="{{list.selected}}"*ngFor="let list of this.clientList" value="{{list.clientId}}">{{list.clientName}}.</option>
        </select>
      </div>
      <div class="col-1" style="padding-top: 8px;margin-right: 65px">
        <label>From</label>
        <input type="date" required="required" [(ngModel)]="fromdate"  class="form-control" />
      </div>
      <div class="col-1" style="padding-top: 8px;margin-right: 45px">
        <label>To</label>
        <input type="date" required="required" [(ngModel)]="todate"  class="form-control" />
      </div>
      <div class="col-2" style="padding-top: 8px;margin-right: 45px">
        <select  [(ngModel)]="searchVal2" (change)="page=1; getTodaySocialMediaData(); ">

          <option value="">--All--</option>
          <option value="L1H">Last 1 Hour</option>
          <option value="L2H">Last 2 Hour</option>
          <option value="L4H">Last 4 Hour</option>
          <option value="L6H">Last 6 Hour</option>
          <option value="L8H">Last 8 Hour</option>
          <option value="L10H">Last 10 Hour</option>
          <option value="L12H">Last 12 Hour</option>
          <option selected value="L24H">Last 24 Hours</option>
          <option value="L7D">Last 7 days</option>
        </select>
      </div>
      <div class="col-1 other-controls" style="padding-top: 13px;margin-right: 5px">
        <i class="fa fa-download" title="Download your report in excel format" style="color:#9a6e3a;font-size: 20px; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="downloadGoogleNewsAsExcel()"></i>
        <i class="fa fa-file-text"  title="Download your report in text format" style=" color: #1a78d5; font-size: 20px; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="downloadGoogleNewsAsText()"></i>
        <i class="fa fa-refresh" title="Refresh your feeds" style="font-size: 20px; color: #00E466; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="getTodaySocialMediaData()"></i>
        <i class="fa fa-cog fa-spin fa-3x fa-fw" style="color: #9F0053" *ngIf="this.isLoading"></i>
        <span class="sr-only">Loading...</span>
      </div>
    </div>
    <section id="extended">

      <div class="card card-primary">

        <div class="card-body align-items-center">

          <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
          <thead class="">
          <tr class="table-info">
            <th *ngIf="userType == 'ADMIN'" scope="col" style="width: 30px">
              <input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="selectAll" (click)="this.selectAllCheckboxes()"/>
              <a href="javascript:;"
                 class="danger p-0">
                <i class="ft-trash font-medium-3" (click)="deleteSocialMediaEntries()"></i>
              </a></th>
            <th scope="col">Headline</th>
<!--            <th scope="col">Short Description</th>-->
            <th scope="col" style="cursor: pointer" (click)="this.sort('publication')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='publication'" ></i>
              Publication
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='publication'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('posted_timestamp')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='posted_timestamp'" ></i>
              Posted On
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='posted_timestamp'" ></i>
            </th>


            <th scope="col" style="cursor: pointer" (click)="this.sort('sentiment')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='sentiment'" ></i>
              Sentiment
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='sentiment'" ></i>
            </th>
            <th *ngIf="userType == 'ADMIN'" scope="col"></th>
          </tr>
          </thead>
          <tbody *ngIf="todaySocialMediaList">
          <tr
            *ngFor="let list of todaySocialMediaList | paginate: { itemsPerPage: 20, currentPage: page, totalItems: totalCount }">
            <td *ngIf="userType == 'ADMIN'"><input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="list.is_selected" value="{{list.id}}"/></td>
            <td><img *ngIf="list.publicationImage" src="{{list.publicationImage}}" style="width: 20px;height: 20px"/> &nbsp;
              <a class="headline" href="{{list.newsUrl}}"
                 innerHTML="{{list.heading.substr(0,350) | highlight: searchVal}} " target="_blank"></a>
              <div class="description">{{list.shortDescription.substr(0,150) }}</div>
            </td>
<!--            <td class="description" style="text-align: left;" innerHTML="{{list.shortDescription.substr(0,250)  | highlight: searchVal}}">{{list.shortDescription.substr(0,150) }}</td>-->
            <td class="socialDate">{{list.publication}}</td>
            <td class="socialDate">{{this.util.formatDateToDDMMYY(list.postedTimeStamp)}}</td>
            <td [ngClass]="list.sentiment == 'POSITIVE' ? 'success' : list.sentiment == 'NEGATIVE' ? 'danger' : 'warning'">{{list.sentiment}}</td>

            <td *ngIf="userType == 'ADMIN'" class="text-truncate">
              <a href="javascript:;" class="info p-0" data-toggle="modal" (click)="openDialog(list)">
                <i class="ft-user font-medium-3 mr-2"></i>
              </a>

              <a href="javascript:;"
                 (click)="deleteSocialMediaEntry(list)" class="danger p-0">
                <i class="ft-trash font-medium-3"></i>
              </a>
              &nbsp;&nbsp;
              <a href="javascript:;"
                 (click)="blockNewsPublication(list)" class="danger p-0">
                <i class="fa fa-ban" font-medium-3 ></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<ul>
          <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }"> ... </li>
        </ul>-->

        <pagination-controls (pageChange)="page = $event; getTodaySocialMediaData()"></pagination-controls>

      </div>
      </div>


    </section>
    <!--Extended Table Ends-->

  </div>
</div>


<!-- Modal -->
<div class="modal fade text-left" id="iconForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel34"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel34">Map Client & Category</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <label>Client: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Client" class="form-control">
            <div class="form-control-position">
              <i class="ft-mail font-medium-2 text-muted"></i>
            </div>
          </div>

          <label>Category: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Category" class="form-control">
            <div class="form-control-position">
              <i class="ft-lock font-medium-2 text-muted"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
          <input type="submit" class="btn btn-primary" value="Add">
        </div>
        <div>
          <table class="display table table-striped table-bordered search-api">
            <thead>
            <tr>
              <th>Client</th>
              <th>Category</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>RBI</td>
              <td>Direct</td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>

