import { Injectable } from '@angular/core';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as moments from 'moment';


const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const TEXT_EXTENSION = '.txt';

@Injectable({
  providedIn: 'root'
})
export class Util {


  getCurrentDate(){
    return moments().format("yyyy-MM-DD")
  }

  getYesterdayDate(){
    return moments().subtract(1,'days').format("yyyy-MM-DD")
  }

  formatDateToDDMMYY(date:string){
    const dt = new Date(new Date(date).getTime());
    let options = {  day: 'numeric', year: 'numeric', month: '2-digit',hour:'2-digit',minute:'2-digit',second:'2-digit'};
    return dt.toLocaleString('en-IN',options).toUpperCase();
  }

  formatDate(date:string){
    const dt = new Date(new Date(date).getTime());
    let options = {  day: 'numeric', year: 'numeric', month: '2-digit'};
    return dt.toLocaleString('en-IN',options).toUpperCase();
  }

  exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  download(buffer: any, fileName: string) {
    var data = new Blob([buffer], { type: 'text/plain;charset=utf-8' });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + TEXT_EXTENSION);
  };



}
