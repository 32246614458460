import {Component, Inject, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {Keyword} from '../keyword/keyword';
import {Activity} from './activity';
import {AllService} from '../../all.service';

@Component({
  selector: 'user-activity-monitor',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})

export class ActivityComponent implements OnInit {
  activityList : Activity[] = [];
  toActivityDate:string='';
  fromActivityDate:string='';
  userid:string='';
  isLoading:boolean = false;
  activity:string='';
  page: number = 1;
  totalCount: number = 0;
  constructor(private allService: AllService, private cookieService: CookieService) {
  }

  ngOnInit(): void {
    this.getUserActivityDetails()
  }

  getUserActivityReport(){
    this.isLoading=true;
    this.allService.getUserActivityReport(this.page,this.userid,this.activity,this.fromActivityDate,this.toActivityDate).subscribe((data: any) => {
      saveAs(data,'todays-activity-report.pdf')
      this.isLoading=false;
    },error => {
      alert('Some Error Occurred');
      this.isLoading=false;
    });
  }

  getUserActivityDetails(){
    this.activityList = [];
    this.allService.getUserActivities(this.page,this.userid,this.activity,this.fromActivityDate,this.toActivityDate).subscribe((data: any) => {
      this.activityList = data.data;
      this.totalCount = data.totalRecordCount;
    });
  }

}
