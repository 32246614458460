<div class=" tableFixHead main-content">
  <div class=" content-wrapper">
    <!--Extended Table starts-->
    <div class="row">
    <div class="col-3">
      <input type="text" [(ngModel)]="searchVal" (keyup.enter)="page=1; getTodaySocialMediaData(); "
             name="search" style="margin-left: 0px" placeholder="Search..">
    </div>
    <div class="col-2"  *ngIf="userType == 'ADMIN'">
      <select  [(ngModel)]="this.searchClient" (change)="page=1; getTodaySocialMediaData(); ">
        <option selected="{{list.selected}}"*ngFor="let list of this.clientList  " value="{{list.clientid}}">{{list.clientname}}.</option>
      </select>
    </div>
    <div class="col-2">
      <select  [(ngModel)]="searchVal2" (change)="page=1; getTodaySocialMediaData(); ">

        <option selected value="">Choose...</option>
        <option value="L1H">Last 1 Hour</option>
        <option value="L24H">Last 24 Hours</option>
        <option value="L7D">Last 7 days</option>
        <option value="MT1HL">More than 100 Likes</option>
      </select>
      <i class="fa fa-file-excel-o" style="font-size: 20px; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="downloadFacebookReport()"></i>
      <i class="fa fa-refresh" title="Refresh your feeds" style="font-size: 20px; color: #00E466; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="getTodaySocialMediaData()"></i>

      <img *ngIf="this.isLoading" src="../../assets/images/loader.gif" id="loading" style="width: 50px"  />
    </div>
    </div>
    <section id="extended">

      <div class=" table-responsive">
        <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
          <thead class="">
          <tr class="table-info">
            <th *ngIf="userType == 'ADMIN'" scope="col" style="width: 30px">
              <input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="selectAll" (click)="this.selectAllCheckboxes()"/>
              <a href="javascript:;"
                 class="danger p-0">
                <i class="ft-trash font-medium-3" (click)="deleteSocialMediaEntries()"></i>
              </a></th>
            <th scope="col">Headline</th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('posted_date')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='posted_date'" ></i>
              Posted On
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='posted_date'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('posted_by')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='posted_by'" ></i>
              Author
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='posted_by'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('likes_numeric_count')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='likes_numeric_count'" ></i>
              Likes#
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='likes_numeric_count'" ></i>
            </th>
            <th scope="col">Comments#</th>
            <th *ngIf="userType == 'ADMIN'" scope="col">Action</th>
          </tr>
          </thead>
          <tbody *ngIf="todaySocialMediaList">
          <tr
            *ngFor="let list of todaySocialMediaList | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount }">
            <td *ngIf="userType == 'ADMIN'"><input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="list.is_selected" value="{{list.id}}"/></td>
            <td>
              <a class="headline" href="{{list.linkToFeed}}"
                 innerHTML="{{list.description.substr(0,250) | highlight: searchVal}}" target="_blank"></a></td>

            <td class="socialDate">{{this.util.formatDateToDDMMYY(list.postedDate)}}</td>
            <td class="warning">{{list.postedBy}}</td>
            <td class="warning">{{list.noOfLikes}}</td>
            <td class="warning">{{list.noOfComments >= 1000000 ? list.noOfComments/1000000 : list.noOfComments}}</td>

            <td *ngIf="userType == 'ADMIN'" class="text-truncate">
              <a href="javascript:;" class="info p-0" data-toggle="modal" (click)="openDialog(list)">
                <i class="ft-user font-medium-3 mr-2"></i>
              </a>

              <a *ngIf="userType == 'ADMIN'" href="javascript:;"
               (click)="deleteSocialMediaEntry(list)" class="danger p-0">
                <i class="ft-trash font-medium-3"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<ul>
          <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }"> ... </li>
        </ul>-->

        <pagination-controls (pageChange)="page = $event; getTodaySocialMediaData()"></pagination-controls>

      </div>


    </section>
    <!--Extended Table Ends-->

  </div>
</div>


<!-- Modal -->
<div class="modal fade text-left" id="iconForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel34"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel34">Map Client & Category</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <label>Client: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Client" class="form-control">
            <div class="form-control-position">
              <i class="ft-mail font-medium-2 text-muted"></i>
            </div>
          </div>

          <label>Category: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Category" class="form-control">
            <div class="form-control-position">
              <i class="ft-lock font-medium-2 text-muted"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
          <input type="submit" class="btn btn-primary" value="Add">
        </div>
        <div>
          <table class="display table table-striped table-bordered search-api">
            <thead>
            <tr>
              <th>Client</th>
              <th>Category</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>RBI</td>
              <td>Direct</td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>

