
  <div class="container-fluid" style="width: 80%">

<div class="card card-primary">
  <div class="card-header">
    <h3 class="card-title">Manage Keywords</h3>
  </div>
  <!-- /.card-header -->
  <!-- form start -->
  <form [formGroup]="FormDemo" >
    <div class="card-body align-items-center">
      <div class="row">
        <div class="col-3">
          <div class="form-group">
            <label>Select Client</label>
            <div>

              <ng-autocomplete
                [data]="data"
                [searchKeyword]="keywordClient"
                (selected)='selectEvent($event)'
                (inputChanged)='onChangeSearch($event)'
                [itemTemplate]="itemTemplate"
                [notFoundTemplate]="notFoundTemplate"
                formControlName="clientObject"
                placeholder="Search Client">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.clientName"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>

            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group ">
            <label>Select Category</label>
            <div class="">

              <ng-autocomplete
                [data]="dataCategory"
                [searchKeyword]="keywordCategory"
                (selected)='selectCategoryEvent($event)'
                (inputChanged)='onClientChangeSearch($event)'
                [itemTemplate]="itemTemplate1"
                [notFoundTemplate]="notFoundTemplate1"
                formControlName="categoryObject"
                placeholder="Category">
              </ng-autocomplete>

              <ng-template #itemTemplate1 let-item>
                <a [innerHTML]="item.categoryName"></a>
              </ng-template>

              <ng-template #notFoundTemplate1 let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>

            </div>
          </div>
        </div>
        <div class="col-2">
          <div class="form-group">
            <label>Required for</label>
            <select class="form-control select2" style="width: 100%;" formControlName="requiredFor">
              <option value="TW" selected="selected">Twitter</option>
              <option value="FB">Facebook</option>
              <option value="YT">Youtube</option>
              <option value="GO">Google</option>
            </select>
          </div>
        </div>
        <div class="col-2" *ngIf="FormDemo.get('requiredFor')?.value=='TW'">
          <div class="form-group">
            <label>Twitter Handle</label>
            <input type="text" class="form-control" formControlName="twitterhandle" />
          </div>
        </div>
        <div class="col-2" *ngIf="FormDemo.get('requiredFor')?.value=='TW'">
          <div class="form-group">
            <label>Opinion Maker</label>
            <input type="text" class="form-control" formControlName="opinionMaker" />
          </div>
        </div>
        <div class="col-2" *ngIf="FormDemo.get('requiredFor')?.value=='GO'">
          <div class="form-group">
            <label>Language</label>
            <select class="form-control select2" style="width: 100%;" formControlName="language">
              <option value="en" selected="selected">English</option>
              <option value="hi">Hindi</option>
            </select>
          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-6">
        <div class="form-group">
          <label>Keywords</label>
          <textarea class="form-control" rows="3"  placeholder="Enter your keywords seperated by comma" formControlName="keywords"></textarea>
        </div>
        </div>
        <div class="col-3">
          <div class="form-group">
            <label>Search Order</label>
            <select class="form-control select2" style="width: 100%;" formControlName="searchOrder">
              <option value="1">Very High</option>
              <option value="2">High</option>
              <option value="3">Medium</option>
              <option value="4">Low</option>
              <option value="5"selected="selected">Very Low</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <!-- /.card-body -->

    <div class="card-footer">
      <button type="submit" class="btn btn-primary" [disabled]="!this.FormDemo.valid" (click)="addKeyword()">Submit</button> &nbsp;
      <button type="submit" class="btn btn-primary" (click)="getKeywordsList()">Search</button>
    </div>
  </form>
  <div >
    <div class="card-body align-items-center">
      <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
        <thead class="">
        <tr class="table-info">
          <th *ngIf="userType == 'ADMIN'" scope="col" style="width: 30px">
            <input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="selectAll" (click)="this.selectAllCheckboxes()"/>
            <a href="javascript:;"
               class="danger p-0">
              <i class="ft-trash font-medium-3" (click)="deleteSelectedKeywords()"></i>
            </a></th>
          <th scope="col">Twitter Handle</th>
          <th scope="col">Keyword</th>
          <th scope="col">Source</th>
        </tr>
        </thead>
        <tbody *ngIf="metdataList.length==0">
          <tr>
            <td colspan="4"><b>No Record Found. Kindly Select Client and Click on search button!</b></td>
          </tr>
        </tbody>
        <tbody *ngIf="metdataList">
        <tr *ngFor="let list of metdataList | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount }">
          <td *ngIf="userType == 'ADMIN'"><input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="list.is_selected" value="{{list.id}}"/></td>
          <td>{{list.clientMetas[0].twitterHandle}} </td>
          <td>{{list.keyword}} </td>
          <td>{{list.source | sourceTransform}} </td>
        </tr>
        </tbody>
      </table>
      <div>
        <pagination-controls (pageChange)="page = $event; getKeywordsList()" style="margin-left: 30%"></pagination-controls>
      </div>
    </div>
  </div>
</div>
      </div>
