
    <div class="content-wrapper">
      <section class="users-edit">
        <div class="row">
          <div class="col-12">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Manage User</h3>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <!-- Nav-tabs -->
                  <ul class="nav nav-tabs" role="tablist">
                    <li class="nav-item">
                      <a href="#account" role="tab" id="account-tab" class="nav-link d-flex align-items-center active" data-toggle="tab" aria-controls="account" aria-selected="true">
                        <i class="ft-user mr-1"></i>
                        <span class="d-none d-sm-block">User</span>
                      </a>
                    </li>

                  </ul>
                  <div class="tab-content">
                    <!-- Account content starts -->
                    <div class="tab-pane fade mt-2 show active" id="account" role="tabpanel" aria-labelledby="account-tab">
                      <!-- Media object starts -->

                      <!-- Media object ends -->

                      <!-- Account form starts -->
                      <form novalidate [formGroup]="UserForm">
                        <div class="row">
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="controls">
                                <label for="username">Username</label>
                                <input type="text" id="username" maxlength="30" minlength="5" [ngClass]="{'not-available': username?.errors?.useralreadytaken,'available': !username?.errors?.useralreadytaken}" name="username" formControlName="username" class="form-control" placeholder="Username" value="dean3004" required aria-invalid="false">
                              </div>
                              <div *ngIf="username?.errors?.useralreadytaken">
                                <span class="mat-error">User already taken</span>
                              </div>

                              <div *ngIf="username?.errors?.minlength?.requiredLength" class="mat-error">
                                Minimum required number is 5.
                              </div>
                              <div *ngIf="username?.errors?.maxlength?.requiredLength" class="mat-error">
                                Maximum required number is 30.
                              </div>
                            </div>

                            <div class="form-group">
                              <div class="controls">
                                <label for="password">Password</label>
                                <input type="password" id="password" class="form-control" formControlName="password" placeholder="Password" value="" required aria-invalid="false">
                              </div>
                              <div *ngIf="password?.errors?.minlength?.requiredLength" class="mat-error">
                                Minimum required number is 10.
                              </div>
                            </div>

                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="controls">
                                <label for="firstname">First Name</label>
                                <input type="text" id="firstname" formControlName="firstname" class="form-control" placeholder="First Name" value="Dean Stanley" required aria-invalid="false">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="controls">
                                <label for="userrole">Roles</label>
                                <select id="userrole" formControlName="userrole" class="form-control" multiple required>
                                  <option value="1">Admin</option>
                                  <option value="2">Social</option>
                                  <option value="3">Online</option>
                                  <option value="4">Print</option>
                                  <option value="6">Electronic</option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="controls">
                                <label for="lastname">Last Name</label>
                                <input type="text" id="lastname" formControlName="lastname" class="form-control" placeholder="Last Name">
                              </div>
                            </div>
                            <div class="form-group">
                              <div class="controls">
                                <label >Client</label>
                                <div class="form-group">
                                  <label>Select Client</label>
                                  <div>

                                    <ng-autocomplete
                                      [data]="data"
                                      [searchKeyword]="client"
                                      (selected)='selectEvent($event)'
                                      (inputChanged)='onChangeSearch($event)'
                                      [itemTemplate]="itemTemplate"
                                      [notFoundTemplate]="notFoundTemplate"
                                      formControlName="clientObject"
                                      placeholder="Search Client">
                                    </ng-autocomplete>

                                    <ng-template #itemTemplate let-item>
                                      <a [innerHTML]="item.clientName"></a>
                                    </ng-template>

                                    <ng-template #notFoundTemplate let-notFound>
                                      <div [innerHTML]="notFound"></div>
                                    </ng-template>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="form-group">
                              <div class="controls">
                                <label for="usertype">User type</label>
                                <select id="usertype" formControlName="usertype" class="form-control" required>
                                  <option value="">Select User type</option>
                                  <option value="ADMIN">Admin</option>
                                  <option value="CLIENT">Client</option>
                                </select>
                              </div>
                            </div>




                          </div>

                          <hr>

                          <div class="col-12 d-flex flex-sm-row flex-column justify-content-end mt-3 mt-sm-2">
                            <button type="submit" class="btn btn-primary mb-2 mb-sm-0 mr-sm-2" (click)="saveUser()">Save Changes</button>
                            <button type="reset" class="btn btn-secondary">Cancel</button>
                          </div>
                        </div>
                      </form>
                      <!-- Account form ends -->
                    </div>
                    <!-- Account content ends -->

                    <!-- Information content starts -->
                    <div class="card-body align-items-center">
                      <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
                        <thead class="">
                        <tr class="table-info">
                          <th scope="col">Username</th>
                          <th scope="col">First Name</th>
                          <th scope="col">Last Name</th>
                          <th scope="col">Type</th>
                          <th scope="col">Action</th>
                        </tr>
                        </thead>
                        <tbody *ngIf="userList.length==0">
                        <tr>
                          <td colspan="4"><b>No Record Found. Kindly Select Client and Click on search button!</b></td>
                        </tr>
                        </tbody>
                        <tbody *ngIf="userList">
                        <tr *ngFor="let list of userList | paginate: { itemsPerPage: 10, currentPage: page, totalItems: totalCount}">
                          <td>{{list.userName}} </td>
                          <td>{{list.firstName}} </td>
                          <td>{{list.lastName}} </td>
                          <td>{{list.userType}} </td>
                          <td><i class="fa fa-trash" (click)="this.handleUserDelete(list.userName)" style="color: red;cursor: pointer "></i> </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                    <pagination-controls (pageChange)="page = $event; getUsersList()" style="margin-left: 50%"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>

        </div>

      </section>
    </div>
