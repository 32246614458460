import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import {YoutubeDialogBodyComponent} from './dialog-body/youtube-dialog-body.component';
import {Util} from '../common/Util';

@Component({
  selector: 'app-social-media',
  templateUrl: './youtube-media.component.html',
  styleUrls: ['./youtube-media.component.scss']
})
export class YoutubeMediaComponent implements OnInit {

  todaySocialMediaList: any;
  searchVal: string;
  page: number = 1;
  fromdate:string='';
  todate:string='';
  searchVal2: string='';
  searchClient: string = '';
  sortDirection: string = 'posted_timestamp';
  columnName: string = 'posted_timestamp';

  isLoading:boolean = false;
  clientList = [
    {clientId:"",clientName:"--All--",selected:true}
  ]

  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;

  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              public util:Util) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
  }


  ngOnInit(): void {
    this.getClientAttachedToAdmin();
    this.getTodaySocialMediaData();
    this.searchVal2 = 'L24H';

  }
  getClientAttachedToAdmin():any{
    if(this.userType=="ADMIN"){
      this.allService.getClients("").subscribe((data: any) => {
        this.clientList = [...this.clientList,...data];
      });

    }
  }
  getTodaySocialMediaData(): void {
    this.isLoading=true;
    this.allService.getYoutubeFeeds(this.searchVal,this.searchVal2,this.page,this.columnName,this.sortDirection,this.searchClient,this.fromdate,this.todate).subscribe((data: any) => {
      console.log(data);
      this.todaySocialMediaList = data;
      this.isLoading=false;
      this.totalCount = data.pages * 20;
    });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;
    this.matDialog.open(YoutubeDialogBodyComponent, dialogConfig);
  }

  deleteSocialMediaEntry(data: any) {
    this.allService.deleteYoutubeMedia(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
    );
  }

}
