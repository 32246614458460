import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../environments/environment';
import {CookieService} from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
              private cookieService: CookieService) { }

  login(data: any) {
    return this.http.post(environment.baseUrl , data);
  }

  logout(data: any) {
    return this.http.post(environment.baseUrl + '/authenticate/signout', data);
  }

  getToken() {
    return !!this.cookieService.get('access_token');
  }
}
