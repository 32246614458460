import {AbstractControl, AsyncValidatorFn, FormControl, ValidationErrors, ValidatorFn} from '@angular/forms';
import {ManageuserService} from '../services/manageuser.service';
import {map} from 'rxjs/operators';

export class UsernameValidator{

  static createValidator(manageuserService:ManageuserService):AsyncValidatorFn {
    return (control: AbstractControl) => {
      return manageuserService.isUseralreadyTaken(control.value).pipe(
        map((data:any)=> {
          return data.message=='Available' ? null : { useralreadytaken: true };
        })
      )
    };
  }
}
