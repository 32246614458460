import { Component, OnInit } from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import jwt_decode from 'jwt-decode';
import {Router} from '@angular/router';
import {AllService} from '../all.service';

// let url = 'https://cdnjs.cloudflare.com/ajax/libs/chartist/0.11.4/chartist.min.js';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  userType: any;
  img_path: any;
  jwtObject : any;
  roles: any;
  todaySocialMediaList: any;
  searchVal: string = '';
  searchVal2: string='TIL24H';
  page:number = 1;
  sortDirection: string = 'desc';
  columnName: string = 'likesNumericCount';

  constructor(
    private cookieService: CookieService,
    private route: Router,
    private allService: AllService
  ) {
    this.userType = this.cookieService.get('userType');
    this.roles = this.cookieService.get('roles').split(",");
    if(this.roles.filter((r: string)=>r === "SOCIAL").length>0)
    {
      this.allService.getSocialMediaData(this.searchVal, this.searchVal2, this.page, this.columnName, this.sortDirection, "").subscribe((data: any) => {
        this.todaySocialMediaList = data;
      });
    }
  }

  ngOnInit(): void {
    this.jwtObject = jwt_decode(this.cookieService.get('access_token'));
    this.img_path = "https://bius-mails.s3.ap-south-1.amazonaws.com/img/wordcloud-"+ this.jwtObject.clientId +".png?rand=10" ;
  }

  gotoTwitterFeeds(){
    this.route.navigate(['/socialMedia'])
  }

  gotoYoutubeFeeds(){
    this.route.navigate(['/youtubeMedia'])
  }
  gotoFacebookFeeds(){
    this.route.navigate(['/facebookMedia'])
  }

  gotoTodaysPrintMedia(){
    this.route.navigate(['/todaysprintmedia/curr'])
  }

}
