import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import {NgxPaginationModule} from 'ngx-pagination';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { TooltipModule } from 'ng2-tooltip-directive';
import {BoldSearch} from './boldText.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import { DashboardComponent } from './dashboard/dashboard.component';
import { OnlineMediaComponent } from './online-media/online-media.component';
import { SocialMediaComponent } from './social-media/social-media.component';
import { ShowclassDirective } from './showclass.directive';
import {Interceptor} from './interceptor/interceptor';
import {CookieService} from 'ngx-cookie-service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {DialogBodyComponent} from './social-media/dialog-body/dialog-body.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSelectModule} from '@angular/material/select';
import { DialogBodyOnlineComponent } from './online-media/dialog-body-online/dialog-body-online.component';
import { NewsDescComponent } from './online-media/news-desc/news-desc.component';
import {AuthService} from './auth.service';
import {ToastrModule} from 'ngx-toastr';
import {OnlineMediaArchieveComponent} from './online-media-archieve/online-media-archieve.component';
import {DialogBodyOnlineArchieveComponent} from './online-media-archieve/dialog-body-online-archieve/dialog-body-online-archieve.component';
import {NewsDescArchieveComponent} from './online-media-archieve/news-desc-archieve/news-desc-archieve.component';
import {SocialMediaArchieveComponent} from './social-media-archieve/social-media-archieve.component';
import {DialogBodyArchieveComponent} from './social-media-archieve/dialog-body-archieve/dialog-body-archieve.component';
import {YoutubeMediaComponent} from './youtube-media/youtube-media.component';
import {YoutubeDialogBodyComponent} from './youtube-media/dialog-body/youtube-dialog-body.component';
import {FacebookMediaComponent} from './facebook-media/facebook-media.component';
import {FacebookDialogBodyComponent} from './facebook-media/dialog-body/facebook-dialog-body.component';
import {GoogleNewsDialogBodyComponent} from './google-news/dialog-body/google-dialog-body.component';
import {GoogleNewsComponent} from './google-news/google-news.component';
import {ManageKeywordComponent} from './admin/keyword/manage-keyword.component';
import {SocialmediaSourcePipe} from './common/socialmedia-source.pipe';
import {PrintMediaComponent} from './print-media/print-media.component';
import {TextNewsComponent} from './print-media/text-news/text-news.component';
import {NgApexchartsModule} from 'ng-apexcharts';
import {UserComponent} from './admin/user/user.component';
import {ActivityComponent} from './admin/activitylogs/activity.component';
import {UsernameValidator} from './validators/username.validator';
import {HomeComponent} from './home/home.component';
import {SearchPrintmediaComponent} from './print-media/search-printmedia/search-printmedia.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    DashboardComponent,
    UserComponent,
    ActivityComponent,
    OnlineMediaComponent,
    SocialMediaComponent,
    YoutubeMediaComponent,
    ShowclassDirective,
    DialogBodyComponent,
    BoldSearch,
    DialogBodyOnlineComponent,
    YoutubeDialogBodyComponent,
    FacebookDialogBodyComponent,
    FacebookMediaComponent,
    NewsDescComponent,
    OnlineMediaArchieveComponent,
    DialogBodyArchieveComponent,
    NewsDescArchieveComponent,
    SocialMediaArchieveComponent,
    DialogBodyOnlineArchieveComponent,
    GoogleNewsDialogBodyComponent,
    GoogleNewsComponent,
    ManageKeywordComponent,
    PrintMediaComponent,
    HomeComponent,
    TextNewsComponent,
    SocialmediaSourcePipe,
    SearchPrintmediaComponent

  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatDialogModule,
        NgxPaginationModule,
        MatSelectModule,
        MatFormFieldModule,
        NgxMatSelectSearchModule,
        MatAutocompleteModule,
        AutocompleteLibModule,
        TooltipModule,

        ToastrModule.forRoot(),
        NgApexchartsModule
    ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true }, CookieService, AuthService,UsernameValidator],
  bootstrap: [AppComponent],
  entryComponents: [DialogBodyComponent, DialogBodyOnlineComponent, NewsDescComponent]
})
export class AppModule { }
