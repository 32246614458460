import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
  constructor(private authService: AuthService,
              private router: Router) {}
  canActivate(): boolean
  {
    if (!this.authService.getToken()) {
      this.router.navigate(['login']);
    }
    return this.authService.getToken();
  }
}
