import {Component, OnInit, Inject, Output,EventEmitter } from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {AllService} from '../../all.service';
import {ToastrService} from "ngx-toastr";
import {Client, Edition, Publication} from '../../app-vo/Publication';

import {CookieService} from 'ngx-cookie-service';
import {PrintmediaSearch} from '../printmedia-search';
import {Util} from '../../common/Util';



@Component({
  selector: 'search-printmedia-body',
  templateUrl: './search-printmedia.component.html',
  styleUrls: ['./search-printmedia.component.scss']
})
export class SearchPrintmediaComponent implements OnInit {

  publicationsList:any;
  isCurrent:boolean=false;
  fromDate:string = this.util.getYesterdayDate();
  toDate:string = this.util.getYesterdayDate();
  publicationKeyword= 'publication';
  headline:string='';
  publicationValue: Publication={
    publicationId:'',
    publication:''
  };
  userType: any;
  clientList:any;
  clientKeyword= 'clientName';
  clientValue: Client={
    clientId:'',
    clientName:''
  };

  editionList:any;
  editionKeyword= 'cityName';
  editionValue: Edition={
    cityId:'',
    cityName:''
  };


  constructor(public dialogRef: MatDialogRef<SearchPrintmediaComponent>,
              @Inject(MAT_DIALOG_DATA) public searchData: any,
              private allService: AllService,
              private util:Util,
              private cookieService: CookieService,
              private toastr: ToastrService) {
    this.userType = this.cookieService.get('userType');
    this.isCurrent = searchData.isCurrent
  }

  ngOnInit(): void {


  }
  @Output() onAdd: EventEmitter<PrintmediaSearch> = new EventEmitter();

  close() {
    this.onAdd.emit(
      {client: this.clientValue,  publication: this.publicationValue,
              edition:this.editionValue,headline:this.headline,
              fromDate:this.fromDate,toDate:this.toDate});
    this.dialogRef.close();
  }



  onSelectPublication(item:Publication){
    this.publicationValue=item;
  }
  onPublicationSearch(val: string){
    this.allService.getPublications(val).subscribe((data:any)=>{
      this.publicationsList = data
    })
  }


  onSelectClient(item:Client){
    this.clientValue=item;
  }
  onClientSearch(val: string) {
    this.allService.getClients(val).subscribe((data: any) => {
      console.log(data)
      this.clientList = data;
    });
  }

  onSelectEdition(item:Edition){
    console.log(item)

    this.editionValue=item;
  }
  onEditionSearch(val: string) {
    this.allService.getEdition(this.publicationValue.publicationId,val).subscribe((data: any) => {
      console.log(data);
      this.editionList = data;
    });
  }




}
