import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {DialogBodyComponent} from './dialog-body/dialog-body.component';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';
import {Util} from '../common/Util';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import {Tweet} from './tweet';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Component({
  selector: 'app-social-media',
  templateUrl: './social-media.component.html',
  styleUrls: ['./social-media.component.scss']
})
export class SocialMediaComponent implements OnInit {

  todaySocialMediaList: Tweet[] = [];
  clientList = [
    {clientId:"",clientName:"--All--",selected:true}
  ]
  searchVal: string = 'L24H';
  searchVal2: string='';
  page:number = 1;
  sortDirection: string = 'desc';
  columnName: string = 'posted_date';
  searchClient: string = '';
  selectedFeedids:string = ''
  selectAll:boolean = false;
  isLoading:boolean = false;
  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;
  roles: any;
  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService,
              public util:Util
              ) {


    this.searchVal = '';
    this.searchVal2 = 'L24H';
    this.userType = this.cookieService.get('userType');
    this.roles = this.cookieService.get('roles').split(",")
  }


  sort(column:string) {
    this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    this.columnName = column;
    this.getTodaySocialMediaData();
  }

  ngOnInit(): void {
    if(this.roles.includes("ADMIN"))
      this.getClientAttachedToAdmin();

    this.getTodaySocialMediaData();
  }

  getClientAttachedToAdmin():any{
    this.allService.getClients("").subscribe((data: any) => {
      this.clientList = [...this.clientList,...data];
    });
  }


  getTodaySocialMediaData(): void {
    this.isLoading=true;
    this.allService.getSocialMediaData(this.searchVal,this.searchVal2,this.page,this.columnName,this.sortDirection,this.searchClient).subscribe((data: any) => {
      this.todaySocialMediaList = data.tweets;
      this.isLoading=false;
      this.totalCount = data.pages * 20;
    });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(DialogBodyComponent, dialogConfig);
  }

  downloadTwitterReport(){
    this.allService.getTwitterReport(this.searchVal).subscribe((data: any) => {
      this.exportAsExcelFile(data,'export-to-excel');
    });
  }

  deleteSocialMediaEntries(){
    this.todaySocialMediaList.forEach(item=>{
      if(item.is_selected)
        this.selectedFeedids+=item.id + ","
    })
    const data = {
      id: this.selectedFeedids.substring(0, this.selectedFeedids.length - 1)
    }
    if(this.selectedFeedids==''){
      alert('Please select atleast 1 row.')
      return false;
    }
    this.deleteSocialMediaEntry(data);
    return ;
  }

  selectAllCheckboxes(){

    this.todaySocialMediaList.forEach(item=>{
      item.is_selected = !this.selectAll
    })
  }

  deleteSocialMediaEntry(data: any) {
    this.page = 1;
    this.allService.deleteSocialMediaData(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.selectedFeedids = '';
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
    );
  }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    console.log('worksheet',worksheet);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
