<div>
  <h1>News Description</h1>
</div>

  <div class="modal-body">
    <p>{{descData.newsDesc}}</p>
  </div>
  <div class="modal-footer">
    <!--<input (click)="close()" type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">-->
  </div>

<style>
  mat-dialog-container {
    width: 300px;
  }
</style>
