import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {OnlineMediaComponent} from './online-media/online-media.component';
import {SocialMediaComponent} from './social-media/social-media.component';
import {AuthenticationGuard} from './authentication.guard';
import { OnlineMediaArchieveComponent } from './online-media-archieve/online-media-archieve.component';
import { SocialMediaArchieveComponent } from './social-media-archieve/social-media-archieve.component';
import {YoutubeMediaComponent} from './youtube-media/youtube-media.component';
import {FacebookMediaComponent} from './facebook-media/facebook-media.component';
import {GoogleNewsComponent} from './google-news/google-news.component';
import {ManageKeywordComponent} from './admin/keyword/manage-keyword.component';
import {PrintMediaComponent} from './print-media/print-media.component';
import {UserComponent} from './admin/user/user.component';
import {ActivityComponent} from './admin/activitylogs/activity.component';
import {HomeComponent} from './home/home.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: DashboardComponent, canActivate: [AuthenticationGuard]},
  { path: 'onlineMedia', component: OnlineMediaComponent, canActivate: [AuthenticationGuard]},
  { path: 'socialMedia', component: SocialMediaComponent, canActivate: [AuthenticationGuard]},
  { path: 'onlineMediaArchieve', component: OnlineMediaArchieveComponent, canActivate: [AuthenticationGuard]},
  { path: 'socialMediaArchieve', component: SocialMediaArchieveComponent, canActivate: [AuthenticationGuard]},
  { path: 'youtubeMedia', component: YoutubeMediaComponent, canActivate: [AuthenticationGuard]},
  { path: 'facebookMedia', component: FacebookMediaComponent, canActivate: [AuthenticationGuard]},
  { path: 'googleNews', component: GoogleNewsComponent, canActivate: [AuthenticationGuard]},
  { path: 'managekeyword', component: ManageKeywordComponent, canActivate: [AuthenticationGuard]},
  { path: 'manageuser', component: UserComponent, canActivate: [AuthenticationGuard]},
  { path: 'todaysprintmedia/:type', component: PrintMediaComponent, canActivate: [AuthenticationGuard]},
  { path: 'useractivity', component: ActivityComponent, canActivate: [AuthenticationGuard]},
  { path: 'home', component: HomeComponent},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
