import { Directive, ElementRef, Input, HostListener } from '@angular/core';

@Directive({
  selector: '[appShowclass]'
})
export class ShowclassDirective {

  constructor(public elementRef: ElementRef) { }
  @Input('appShowclass') showClass: any;

  @HostListener('mouseenter') onMouseEnter() {
    this.elementRef.nativeElement.classList.add(this.showClass);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.elementRef.nativeElement.classList.remove(this.showClass);
  }
}
