import { Component, OnInit } from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import {DialogBodyArchieveComponent} from './dialog-body-archieve/dialog-body-archieve.component';
import {AllService} from '../all.service';
import {ToastrService} from "ngx-toastr";
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-social-media-archieve',
  templateUrl: './social-media-archieve.component.html',
  styleUrls: ['./social-media-archieve.component.scss']
})
export class SocialMediaArchieveComponent implements OnInit {

  todaySocialMediaList: any;
  searchVal: string;
  page:number = 1;

  p: number = 1;
  collection: any;
  totalCount:number = 1;
  userType: any;

  constructor(private matDialog: MatDialog,
              private allService: AllService,
              private toastr: ToastrService,
              private cookieService: CookieService) {

    this.searchVal = '';
    this.userType = this.cookieService.get('userType');
  }


  ngOnInit(): void {
    this.getTodaySocialMediaData();
  }

  getTodaySocialMediaData(): void {

      /*this.todaySocialMediaList = {

        "tweets": [

          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "media could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          },
          {

            "id": "605807724e84d19bccf60eec",

            "tweet": "mwdfedia could not be played.ReloadReload",

            "likesCount": null,

            "commentCount": null,

            "tweetedBy": "",

            "noOfReplies": "",

            "reTweetsCount": "",

            "tweetedOn": null,

            "clientMetaData": [

              {

                "category": "Direct",

                "client": "KIR100",

                "opinionMaker": "Ram Lal"

              }

            ]

          }

        ],

        "pages": 13,

        "pageNo": 2

      }*/

      // this.collection = this.todaySocialMediaList.tweets;
      // this.totalCount = this.todaySocialMediaList.tweets.length * this.todaySocialMediaList.pages;

    //   this.allService.getSocialMediaData(this.searchVal, this.page).subscribe((data: any) => {
    //   this.todaySocialMediaList = data;
    //   this.totalCount = this.todaySocialMediaList.pages * 10;
    // });
  }

  openDialog(data: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = data;

    // dialogConfig.data.type = type;
    this.matDialog.open(DialogBodyArchieveComponent, dialogConfig);
  }

  deleteSocialMediaEntry(data: any) {
    this.allService.deleteSocialMediaData(data.id).subscribe(
      () =>{
        this.toastr.success(data.message, "Success")
        this.getTodaySocialMediaData();
      } ,
      (err)=>console.log('In Error')
    );

    // this.allService.deleteSocialMediaData(data.id).subscribe((data: any) => {
    //   this.toastr.success("Entry Deleted..!!", "Success")
    // });

  }

}
