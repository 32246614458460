import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'sourceTransform'})
export class SocialmediaSourcePipe implements PipeTransform {
  transform(value: string): string {
    if(value==="GO")
      return "Google";
    else if(value==="TW")
      return "Twitter";
    else if(value==="FB")
      return "Facebook";
    else
      return "NA";
  }
}
