import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';


@Injectable({
  providedIn: 'root'
})
export class AppAlert {

  showConfirmBox(){
    return Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this details!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel'
    });
  }

  showSuccessBox(){
    Swal.fire(
    'Deleted!',
    'Feed has been deleted.',
    'success'
    );
  }

  showUnAuthorisedAccess(link:string){
    Swal.fire(
      'Not Authorised!',
      'You are not authorised to access ' + link,
      'error'
    );
  }
}
