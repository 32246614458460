<div class=" tableFixHead main-content">
  <div class=" content-wrapper">
    <!--Extended Table starts-->
    <div class="row">
      <div class="col-3">
        <input type="text" [(ngModel)]="searchVal" (keyup.enter)="page=1; getTodaySocialMediaData(); "
               name="search" style="margin-left: 0px" placeholder="Search..">
      </div>
      <div class="col-2"  *ngIf="userType == 'ADMIN'" >
        <select  [(ngModel)]="this.searchClient" (change)="page=1; getTodaySocialMediaData(); ">
          <option selected="{{list.selected}}"*ngFor="let list of this.clientList" value="{{list.clientId}}">{{list.clientName}}.</option>
        </select>
      </div>
      <div class="col-1">
        <label>From</label>
        <input type="date" required="required" [(ngModel)]="fromdate"  class="form-control" />
      </div>
      <div class="col-1">
        <label>To</label>
        <input type="date" required="required" [(ngModel)]="todate"  class="form-control" />
      </div>

      <div class="col-2">
        <select  [(ngModel)]="searchVal2" (change)="page=1; getTodaySocialMediaData(); ">

          <option value="">--All--</option>
          <option value="L1H">Last 1 Hour</option>
          <option selected value="L24H">Last 24 Hours</option>
          <option value="L7D">Last 7 days</option>
        </select>
      </div>

      <div class="col-1 other-controls">
        <i class="fa fa-refresh" title="Refresh your feeds" style="font-size: 20px; color: #00E466; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="getTodaySocialMediaData()"></i>
        <span class="sr-only">Loading...</span>
      </div>

    </div>
    <section id="extended">

      <div class=" table-responsive">
        <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
          <thead class="">
          <tr class="table-info">
            <th scope="col">Headline</th>
            <th scope="col">Posted On</th>
            <th scope="col">Views</th>
            <th *ngIf="userType == 'ADMIN'" scope="col">Action</th>
          </tr>
          </thead>
          <tbody *ngIf="todaySocialMediaList">
          <tr
            *ngFor="let list of todaySocialMediaList.videos | paginate: { itemsPerPage: 20, currentPage: page, totalItems: totalCount }">
            <td>
              <a class="headline" href="{{list.source + list.link}}"
                 innerHTML="{{list.title | highlight: searchVal}}" target="_blank"></a></td>
            <td class="socialDate">{{this.util.formatDateToDDMMYY(list.releaseDate)}}</td>
            <td class="warning">{{list.noOfViews}}</td>

            <td *ngIf="userType == 'ADMIN'" class="text-truncate">
              <a *ngIf="userType == 'ADMIN'"  href="javascript:;" class="info p-0" data-toggle="modal" (click)="openDialog(list)">
                <i class="ft-user font-medium-3 mr-2"></i>
              </a>

              <a *ngIf="userType == 'ADMIN'" href="javascript:;"
               (click)="deleteSocialMediaEntry(list)" class="danger p-0">
                <i class="ft-trash font-medium-3"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<ul>
          <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }"> ... </li>
        </ul>-->

        <pagination-controls (pageChange)="page = $event; getTodaySocialMediaData()"></pagination-controls>

      </div>

      <div class="modal fade text-left" id="bootstrap" tabindex="-1" role="dialog" aria-labelledby="myModalLabel35"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="myModalLabel35"> Modal Title</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
              </button>
            </div>
            <form>
              <div class="modal-body">
                <fieldset class="form-group floating-label-form-group">
                  <label for="email">Email Address</label>
                  <input type="text" class="form-control" id="email" placeholder="Email Address">
                </fieldset>
                <br>
                <fieldset class="form-group floating-label-form-group">
                  <label for="title">Password</label>
                  <input type="password" class="form-control" id="title" placeholder="Password">
                </fieldset>
                <br>
                <fieldset class="form-group floating-label-form-group">
                  <label for="title1">Description</label>
                  <textarea class="form-control" id="title1" rows="3" placeholder="Description"></textarea>
                </fieldset>
              </div>
              <div class="modal-footer">
                <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
                <input type="submit" class="btn btn-primary" value="Login">
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!--Extended Table Ends-->

  </div>
</div>


<!-- Modal -->
<div class="modal fade text-left" id="iconForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel34"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel34">Map Client & Category</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <label>Client: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Client" class="form-control">
            <div class="form-control-position">
              <i class="ft-mail font-medium-2 text-muted"></i>
            </div>
          </div>

          <label>Category: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Category" class="form-control">
            <div class="form-control-position">
              <i class="ft-lock font-medium-2 text-muted"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
          <input type="submit" class="btn btn-primary" value="Add">
        </div>
        <div>
          <table class="display table table-striped table-bordered search-api">
            <thead>
            <tr>
              <th>Client</th>
              <th>Category</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>RBI</td>
              <td>Direct</td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>

<style>
  input[type=text] {
    width: 130px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: white;
    background-image: url('../../assets/images/searchicon.png');
    background-position: 10px 10px;
    background-repeat: no-repeat;
    padding: 8px 20px 8px 40px;
    -webkit-transition: width 0.4s ease-in-out;
    transition: width 0.4s ease-in-out;
    margin: 4px;
  }

  input[type=text] {
    width: 100%;
    border-radius: 15px;
  }

  .content-header {
    font-size: 25px !important;
    margin-top: 14px !important;
  }

  .table th, .table td {
    padding: 10px;
  }

  pagination-controls {
    display: block;
    text-align: center;
    margin-top: 40px;
  }

  .table-responsive, .card {
    border-radius: 30px;
  }

  tbody tr td:first-child {
    text-align: left;
  }

  .mediaTable tbody tr td a.headline, .mediaTable tbody tr td.socialDate {
    display: block;
    max-height: 65px;
    overflow: auto;
  }

  .mediaTable tbody tr td a.headline {
    color: #012e5a;
  }

  .tableFixHead {
    overflow-y: auto;;
  }

  .tableFixHead thead th {
    position: sticky;
    top: 0;
  }

  pagination-controls {
    margin-top: 15px;
  }

</style>
