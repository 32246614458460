import { Component} from '@angular/core';
import {Router} from '@angular/router';
import {CookieService} from 'ngx-cookie-service';
import {AuthService} from './auth.service';
import {AppAlert} from './common/AppAlert';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'biusUiApp';
  loginPage = false;
  homePage = false;
  searchVal: any;
  pageHeading = '';
  imgUrl = '';
  username: any;
  userType: any;
  roles: any;

  constructor(private router: Router,
              private cookieService: CookieService,
              private authService: AuthService,
              private alerts:AppAlert) {

              this.username = this.cookieService.get('name');
              this.userType = this.cookieService.get('userType');
              this.roles = this.cookieService.get('roles').split(",");

  }

  ngAfterViewChecked(): void {
    if (this.router.url === '/login' || this.router.url === '/home') {
      this.loginPage = true;
      this.pageHeading = 'Login';
    }

    else {
      this.loginPage = false;
    }

    if (this.router.url === '/socialMedia') {
      this.pageHeading = '';
      this.imgUrl = '../assets/images/twitter.png';
    }
    else if (this.router.url === '/onlineMedia') {
      this.pageHeading = 'Today\'s Online Media Feeds';
    }
    else if (this.router.url === '/dashboard') {
      this.pageHeading = '';
      this.imgUrl ='https://bius-mails.s3.ap-south-1.amazonaws.com/img/Dashboard.png'

    }else if (this.router.url === '/youtubeMedia') {
      this.pageHeading = '';
      this.imgUrl ='../assets/images/youtube.png'
    }else if (this.router.url === '/facebookMedia') {
      this.pageHeading = '';
      this.imgUrl ='../assets/images/facebook.png'
    }else if (this.router.url === '/googleNews') {
      this.pageHeading = '';
      this.imgUrl ='../assets/images/google.png'
    }else if (this.router.url === '/todaysprintmedia/curr') {
      this.pageHeading = '';
      this.imgUrl = '../assets/images/tpm.png'
    }else if (this.router.url === '/todaysprintmedia/arch') {
      this.pageHeading = '';
      this.imgUrl = '../assets/images/apm.png'
    }else{
      this.pageHeading = '';
      this.imgUrl = ''
    }

  }

  goToDashboard() {
    this.router.navigate(['dashboard'])
      .then(() => {
        window.location.reload();
      });
  }

  goToLink(link:string){
    if(this.roles.includes('ONLINE') && link==='Online News')
      this.router.navigate(['googleNews'])
    else if(this.roles.includes('SOCIAL') && link==='Social News')
      this.router.navigate(['googleNews'])

    this.alerts.showUnAuthorisedAccess('Online News');

  }


  logout() {
    let data = {
      jwtToken: this.cookieService.get('access_token')
    }
    this.authService.logout(data).subscribe((data: any) => {
      this.cookieService.deleteAll()
      this.router.navigate(['login'])
      console.log("logged out");
    })
  }
}
