<div>
  <h1>Search News</h1>
</div>
<form>
  <div class="modal-body">
    <div class="row" *ngIf="userType == 'ADMIN'">
      <div class="col-6">
          <label>Client: </label>
          <div class="form-group position-relative has-icon-left">

            <ng-autocomplete
              [data]="clientList"
              [searchKeyword]="clientKeyword"
              (selected)='onSelectClient($event)'
              (inputChanged)='onClientSearch($event)'
              [itemTemplate]="clientTemplate"
              [notFoundTemplate]="notFoundTemplate"
              placeholder="Client">
            </ng-autocomplete>

            <ng-template #clientTemplate let-item>
              <a [innerHTML]="item.clientName"></a>
            </ng-template>

            <ng-template #notFoundTemplate let-notFound>
              <div [innerHTML]="notFound"></div>
            </ng-template>
          </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
      <div *ngIf="!this.isCurrent" class="col-1" style="padding-top: 8px;margin-right: 45px">
        <input type="date" class="form-control date-controls" [(ngModel)]="fromDate" style="width: 155px;border-radius: 4px"/>
      </div>
      </div>
      <div class="col-6">
      <div *ngIf="!this.isCurrent" class="col-2" style="padding-top: 8px">
        <input type="date" class="form-control date-controls" [(ngModel)]="toDate" style="width: 155px;border-radius: 4px"/>
      </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label>Publication: </label>
        <div class="form-group position-relative has-icon-left">

          <ng-autocomplete
            [data]="publicationsList"
            [searchKeyword]="publicationKeyword"
            (selected)='onSelectPublication($event)'
            (inputChanged)='onPublicationSearch($event)'
            [itemTemplate]="itemTemplate"
            [notFoundTemplate]="notFoundTemplate"
            placeholder="Publication">
          </ng-autocomplete>

          <ng-template #itemTemplate let-item>
            <a [innerHTML]="item.publication"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
      <div class="col-6">
        <label>Edition: </label>
        <div class="form-group position-relative has-icon-left">

          <ng-autocomplete
            [data]="editionList"
            [searchKeyword]="editionKeyword"
            (selected)='onSelectEdition($event)'
            (inputChanged)='onEditionSearch($event)'
            [itemTemplate]="editionTemplate"
            [notFoundTemplate]="notFoundTemplate"
            placeholder="Edition">
          </ng-autocomplete>

          <ng-template #editionTemplate let-item>
            <a [innerHTML]="item.cityName"></a>
          </ng-template>

          <ng-template #notFoundTemplate let-notFound>
            <div [innerHTML]="notFound"></div>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="row">

      <div class="col-6">
        <label>Headline: </label>
        <div class="form-group position-relative has-icon-left">
          <input type="text" class="form-control" [(ngModel)]="headline"  name="search" style="margin-left: 0px" placeholder="Headline">

        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <input (click)="close()" type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Search">
  </div>
</form>
