<div class=" tableFixHead main-content">
  <div class=" content-wrapper">
    <!--Extended Table starts-->
    <div class="row">
      <div class="col-3">
        <input type="text" [(ngModel)]="searchVal"  (keyup.enter)="page=1; getTodaySocialMediaData(); "
               name="search" style="margin-left: 0px" placeholder="Search..">
      </div>
      <div class="col-4"  *ngIf="roles.includes('ADMIN')">
        <select  [(ngModel)]="this.searchClient" (change)="page=1; getTodaySocialMediaData(); ">
          <option selected="{{list.selected}}"*ngFor="let list of this.clientList  " value="{{list.clientId}}">{{list.clientName}}.</option>
        </select>
      </div>
      <div class="col-2">
        <select  [(ngModel)]="searchVal2" (change)="page=1; getTodaySocialMediaData(); ">

          <option value="">All</option>
          <option value="L1H">Last 1 Hour</option>
          <option selected value="L24H">Last 24 Hours</option>
          <option value="L7D">Last 7 days</option>
          <option value="MT1HL">More than 100 Likes</option>
          <option value="MTKF">More than 10K Followers</option>
          <option value="TIL24H">Trending in last 24 Hours</option>
        </select>
        <i class="fa fa-download" title="Download your report in excel format" style="font-size: 20px; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="downloadTwitterReport()"></i>
        <i class="fa fa-refresh" title="Refresh your feeds" style="font-size: 20px; color: #00E466; cursor: pointer ;padding: 2px 1px 1px 10px;" aria-hidden="false" (click)="getTodaySocialMediaData()"></i>
        <img *ngIf="this.isLoading" src="../../assets/images/loader.gif" id="loading" style="width: 50px"  />
      </div>




    </div>
    <section id="extended">
      <div class="card card-primary">

      <div class="card-body align-items-center">

        <table class="mediaTable table table-striped table-bordered table-hover text-center m-0">
          <thead class="">
          <tr class="table-info">
            <th *ngIf="userType == 'ADMIN'" scope="col" style="width: 30px">
              <input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="selectAll" (click)="this.selectAllCheckboxes()"/>
              <a href="javascript:;"
                                     class="danger p-0">
              <i class="ft-trash font-medium-3" (click)="deleteSocialMediaEntries()"></i>
            </a></th>
            <th scope="col">Headline</th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('tweetedBy')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='tweetedBy'" ></i>
              Author
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='tweetedBy'" ></i>
            </th>

            <th scope="col" style="cursor: pointer" (click)="this.sort('posted_date')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='posted_date'" ></i>
              Posted On
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='posted_date'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('tweets_with_followers.followersNumericCount')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='tweets_with_followers.followersNumericCount'" ></i>
              Followers#
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='tweets_with_followers.followersNumericCount'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('likesNumericCount')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='likesNumericCount'" ></i>
              <i class="fa fa-thumbs-up" style="color: #ed1e79;font-size: 15px" title="Likes"></i>
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='likesNumericCount'" ></i>
            </th>
            <!--<th>Comment #</th>-->
            <th scope="col" style="cursor: pointer" (click)="this.sort('noOfNumericReplies')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='noOfNumericReplies'" ></i>
              <i class="fa fa-reply " style="color: #1E294F;font-size: 15px" title="Replies"></i>
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='noOfNumericReplies'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('reTweetsNumericCount')" >
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='reTweetsNumericCount'" ></i>
              <i class="fa fa-retweet" style="color: #00E466;font-size: 15px" title="Replies"></i>
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='reTweetsNumericCount'" ></i>
            </th>
            <th scope="col" style="cursor: pointer" (click)="this.sort('sentiment')">
              <i class="fa fa-chevron-up" *ngIf="sortDirection === 'asc' && columnName==='sentiment'" ></i>
              Sentiment
              <i class="fa fa-chevron-down" *ngIf="sortDirection === 'desc' && columnName==='sentiment'" ></i>
            </th>
            <th *ngIf="userType == 'ADMIN'" scope="col"></th>
          </tr>
          </thead>
          <tbody *ngIf="todaySocialMediaList">
          <tr
            *ngFor="let list of todaySocialMediaList | paginate: { itemsPerPage: 20, currentPage: page, totalItems: totalCount }">
            <td *ngIf="userType == 'ADMIN'"><input type="checkbox" style=" width: 20px;height: 20px;" [(ngModel)]="list.is_selected" value="{{list.id}}"/></td>
            <td >
              <a class="headline" href="{{'https://twitter.com' + list.tweetUrl}}"
                 innerHTML="{{list.tweet.substr(0,250) | highlight: searchVal}}" target="_blank"></a></td>
            <td class="socialDate">{{list.tweetedBy | slice:0:25}} </td>
            <td class="socialDate">{{this.util.formatDateToDDMMYY(list.tweetedOn)}}</td>
            <td class="danger"> <span class="followers" *ngIf="list.followers && list.followers[0]" >{{list.followers[0].noOfFollowers}}</span></td>
            <td class="danger">{{list.likesCount}}</td>
            <!--<td>{{list.commentCount}}</td>-->
            <td class="warning">{{list.noOfReplies}}</td>
            <td class="success">{{list.reTweetsCount}}</td>
            <td [ngClass]="list.sentiment == 'POSITIVE' ? 'success' : list.sentiment == 'NEGATIVE' ? 'danger' : 'warning'">{{list.sentiment}}</td>

            <td *ngIf="userType == 'ADMIN'" class="text-truncate">
              <a href="javascript:;" class="info p-0" data-toggle="modal" (click)="openDialog(list)">
                <i class="ft-user font-medium-3 mr-2"></i>
              </a>

              <a href="javascript:;"
               (click)="deleteSocialMediaEntry(list)" class="danger p-0">
                <i class="ft-trash font-medium-3"></i>
              </a>
            </td>
          </tr>
          </tbody>
        </table>
        <!--<ul>
          <li *ngFor="let item of collection | paginate: { itemsPerPage: 10, currentPage: p }"> ... </li>
        </ul>-->

        <pagination-controls (pageChange)="page = $event; getTodaySocialMediaData()"></pagination-controls>

      </div>
      </div>
      <div class="modal fade text-left" id="bootstrap" tabindex="-1" role="dialog" aria-labelledby="myModalLabel35"
           aria-hidden="true">
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h3 class="modal-title" id="myModalLabel35"> Modal Title</h3>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
              </button>
            </div>
            <form>
              <div class="modal-body">
                <fieldset class="form-group floating-label-form-group">
                  <label for="email">Email Address</label>
                  <input type="text" class="form-control" id="email" placeholder="Email Address">
                </fieldset>
                <br>
                <fieldset class="form-group floating-label-form-group">
                  <label for="title">Password</label>
                  <input type="password" class="form-control" id="title" placeholder="Password">
                </fieldset>
                <br>
                <fieldset class="form-group floating-label-form-group">
                  <label for="title1">Description</label>
                  <textarea class="form-control" id="title1" rows="3" placeholder="Description"></textarea>
                </fieldset>
              </div>
              <div class="modal-footer">
                <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
                <input type="submit" class="btn btn-primary" value="Login">
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <!--Extended Table Ends-->

  </div>
</div>


<!-- Modal -->
<div class="modal fade text-left" id="iconForm" tabindex="-1" role="dialog" aria-labelledby="myModalLabel34"
     aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h3 class="modal-title" id="myModalLabel34">Map Client & Category</h3>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true"><i class="ft-x font-medium-2 text-bold-700"></i></span>
        </button>
      </div>
      <form>
        <div class="modal-body">
          <label>Client: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Client" class="form-control">
            <div class="form-control-position">
              <i class="ft-mail font-medium-2 text-muted"></i>
            </div>
          </div>

          <label>Category: </label>
          <div class="form-group position-relative has-icon-left">
            <input type="text" placeholder="Category" class="form-control">
            <div class="form-control-position">
              <i class="ft-lock font-medium-2 text-muted"></i>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input type="reset" class="btn bg-light-secondary" data-dismiss="modal" value="Close">
          <input type="submit" class="btn btn-primary" value="Add">
        </div>
        <div>
          <table class="display table table-striped table-bordered search-api">
            <thead>
            <tr>
              <th>Client</th>
              <th>Category</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>RBI</td>
              <td>Direct</td>
            </tr>
            </tbody>
          </table>
        </div>
      </form>
    </div>
  </div>
</div>
