<div class="main-content">
  <div class="content-overlay"></div>
  <div class="content-wrapper">
    <section class="users-list-wrapper">


      <!-- Table starts -->
      <div class="users-list-table">
        <div class="row">
          <div class="col-12">
            <div class="card card-primary">
              <div class="card-header">
                <h3 class="card-title">Activity Monitor</h3>
              </div>
              <div class="card-content">
                <div class="card-body">
                  <!-- Filter starts -->
                  <div class="users-list-filter px-2">
                    <form>
                      <div class="row border rounded py-2 mb-2 mx-n2">
                        <div class="col-12 col-sm-6 col-lg-3">
                          <label for="users-list-verified">User</label>
                          <fieldset class="form-group">
                            <select id="users-list-verified" class="form-control" [(ngModel)]="userid">
                              <option value="0">--All--</option>
                              <option value="KIR0000028">ABHISHEK</option>
                              <option value="KIR0000041">ADARSH</option>
                              <option value="KIR0000029">AMIT</option>
                              <option value="KIR0000007">ANAND</option>
                              <option value="KIR0000037">ARADHANA SHARMA</option>
                              <option value="KIR0000044">DALJIT</option>
                              <option value="KIR0000025">DEEPAK</option>
                              <option value="KIR0000040">DHARAMVEER</option>
                              <option value="KIR0000011">JITENDRA</option>
                              <option value="KIR0000024">MANIKANDAN</option>
                              <option value="KIR0000027">MUKESH</option>
                              <option value="KIR0000016">NARINDAR</option>
                              <option value="KIR0000004">NEETU BAJAJ</option>
                              <option value="KIR0000031">NITIN</option>
                              <option value="KIR0000034">PRASHANT</option>
                              <option value="KIR0000030">PRITAM</option>
                              <option value="KIR0000038">RENU</option>
                              <option value="KIR0000014">ROBIN KR. VERMA</option>
                              <option value="KIR0000009">SUMAN</option>
                              <option value="KIR0000033">SUNIL</option>
                              <option value="KIR0000045">SYED</option>
                            </select>
                          </fieldset>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3">
                          <label for="users-list-verified">Activity</label>
                          <fieldset class="form-group">
                            <select id="users-activity" class="form-control" [(ngModel)]="activity">
                              <option value="ALL">--All--</option>
                              <option value="LOGIN">LOGIN</option>
                              <option value="LOGOUT">Logout</option>
                              <option value="PRINT-MEDIA">Print Media</option>
                              <option value="ELECTRONIC-MEDIA">Electronic Media</option>
                              <option value="DELETING">Deleting</option>
                              <option value="SAVING">Saving</option>
                              <option value="PRINT-PDF-LOGO-MERGE">Pdf Logo & Merge</option>
                              <option value="BACKUP-CLIENT">Back Up Client</option>
                            </select>
                          </fieldset>
                        </div>

                        <div class="col-12 col-sm-6 col-lg-2">
                          <label >From</label>
                          <input type="datetime-local" required="required" class="form-control" [(ngModel)]="fromActivityDate" style="width: 190px;border-radius: 4px"/>
                        </div>
                        <div>
                          <label >To</label>
                          <input type="datetime-local" required="required" class="form-control" [(ngModel)]="toActivityDate" style="width: 190px;border-radius: 4px"/>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 d-flex align-items-center">
                          <i class="fa fa-search" style="font-size: 20px;color: #975AFF" (click)="this.getUserActivityDetails()"></i>
                          <i class="fa fa-download" title="Download Today's Activity Report" style="font-size: 20px;color: #975AFF" (click)="this.getUserActivityReport()"></i>
                          <i class="fa fa-cog fa-spin fa-3x fa-fw" style="color: #9F0053" *ngIf="this.isLoading"></i>
                        </div>
                      </div>
                    </form>
                  </div>
                  <!-- Filter ends -->
                  <!-- Datatable starts -->
                  <div class="table-responsive ">
                    <table id="users-list-datatable" class="table table-bordered table-hover">
                      <thead>
                      <tr>
                        <th>Username</th>
                        <th>Name</th>
                        <th>Activity Name</th>
                        <th>Activity Time</th>
                        <th>Status</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor="let activity of this.activityList | paginate: { itemsPerPage: 15, currentPage: page, totalItems: totalCount }">
                        <td>{{activity.loginId}}</td>
                        <td>{{activity.username}}</td>
                        <td>{{activity.activity}}</td>
                        <td>{{activity.formattedDate}}</td>
                        <td>Active</td>

                      </tr>

                      </tbody>
                    </table>
                  </div>
                  <!-- Datatable ends -->
                </div>
              </div>
              <div>
                <pagination-controls (pageChange)="page = $event; getUserActivityDetails()" style="margin-left: 30%"></pagination-controls>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!-- Table ends -->
    </section>

  </div>
</div>
